import React from "react";
import "./ProductCardsModems.css";
import { Link } from "react-router-dom"; 
import '../i18n';
import { useTranslation } from 'react-i18next';

import Spin12_BEACON from './product_cards/Spin12_BEACON'
import Spin21_BEACON from './product_cards/Spin21_BEACON'
import Spin26_BEACON from './product_cards/Spin26_BEACON'
import Spin26H_BEACON from './product_cards/Spin26H_BEACON'
import Spin57_BEACON from './product_cards/Spin57_BEACON'

const ProductCardsModems = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <div className="Products">
      <div class="card-list-modems">

        <Spin12_BEACON />
        <Spin21_BEACON />
        <Spin26_BEACON />
        <Spin26H_BEACON />
        <Spin57_BEACON />

      </div>
    </div>
  );
};
export default ProductCardsModems;