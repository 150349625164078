import React, { useEffect } from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Products from './components/pages/Products';
import News from './components/pages/News';
import AboutUs from './components/pages/AboutUs';
import ContactUs from './components/pages/ContactUs';
import USBLProduct from './components/pages/USBLProduct';
import AcousticModems from './components/pages/AcousticModems'
import Accessoires from './components/pages/Accessoires'
import Software from './components/pages/Software'
import Integration from './components/pages/Integration'
import Career from './components/pages/Career'

import i18n from './i18n';
import Footer from './components/Footer'

const countryLanguageMap = {
  US: 'en',
  DE: 'de',
  RU: 'ru'
};

function changeLanguage(lng) {
  i18n.changeLanguage(lng);
}

function switchLanguageByBrowserLocale() {
  const browserLanguage = navigator.language || 'en';
  changeLanguage(browserLanguage.split('-')[0]);
}

function detectLanguageByLocation(lat, lng) {
  fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lng}&localityLanguage=en`)
    .then((response) => response.json())
    .then((data) => {
      const countryCode = data.countryCode;
      const language = countryLanguageMap[countryCode] || 'en';
      changeLanguage(language);
    })
    .catch((error) => {
      changeLanguage('en');
    });
}

function App() {
  // This useEffect will run once when the component mounts
  useEffect(() => {
    // Use geolocation first
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          detectLanguageByLocation(latitude, longitude);
        },
        (error) => {
          console.error('Error fetching location:', error);
          // Fallback to browser language
          switchLanguageByBrowserLocale();
        }
      );
    } else {
      switchLanguageByBrowserLocale(); // Fallback if geolocation is not supported
    }
  }, []); // Empty dependency array ensures this runs only on mount

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/products" element={<Products />} />
        <Route exact path="/news" element={<News />} />
        <Route exact path="/career" element={<Career />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/contact" element={<ContactUs />} />
        <Route exact path="/usbl-product" element={<USBLProduct />} />
        <Route exact path="/acoustic_modems" element={<AcousticModems />} />
        <Route exact path="/accessoires" element={<Accessoires />} />
        <Route exact path="/software" element={<Software />} />
        <Route exact path="/integration" element={<Integration />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
