import React from 'react';
import './ContactUS.css';
import '../../i18n';
import { useTranslation } from 'react-i18next';

export default function ContactUs() {
  const { t, i18n } = useTranslation();
  return (
    <div className="contact-page">
      <div className="contact-us">
        <h2>{t('contact.title')}</h2>
        <div className="contact-section">
        <p>
          {t('contact.text')}
        </p>
        </div>
        <div className="contact-section">
          <div className="section-title">{t('contact.company_title')}</div>
          <p><strong>{t('contact.company_name')}</strong></p>
          <p> {t('contact.address')} </p>
          <p><strong>{t('contact.phone')}</strong> +7(911)7778001</p>
          <p><strong>{t('contact.email')}</strong> <a href="mailto:sales@latena.ru">sales@latena.ru</a></p>
        </div>

        <div className="details-section">
          <div className="section-title">{t('contact.company_details')}</div>
          <p><strong>{t('contact.company_details_a.INN')}</strong> 7801284389, <strong>{t('contact.company_details_a.KPP')}</strong> 780101001</p>
          <p><strong>{t('contact.company_details_a.OGRN')}</strong> 1157847236095, <strong>{t('contact.company_details_a.OKPO')}</strong> 23118537</p>
          <p><strong>{t('contact.company_details_a.OKVED')}</strong> 33.20.7, 73.10, 72.20</p>
          <p><strong>{t('contact.company_details_a.OKATO')}</strong> 40263561000, <strong>{t('contact.company_details_a.ОКТМО')}</strong> 40307000000</p>
          <p><strong>{t('contact.company_details_a.ОКОGU')}</strong> 4210014, <strong>{t('contact.company_details_a.ОКFS')}</strong> 16, <strong>{t('contact.company_details_a.OKOPF')}</strong> 12267</p>
        </div>

        <div className="bank-section">
          <div className="section-title">{t('contact.bank_details')}</div>
          <p>{t('contact.bank_details_a.rs')}40702810355040008337</p>
          <p>{t('contact.bank_details_a.bank_name')}</p>
          <p>{t('contact.bank_details_a.bic')}044030653, {t('contact.bank_details_a.ks')}30101810500000000653</p>
        </div>
      </div>
    </div>
  );
}
