import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import './Products.css';
import './USBLProduct.css';
import '../ProductCards.css';
import { useTranslation } from 'react-i18next';

export default function Integration () {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  
  const navigate = useNavigate();

  const exploreMore = () => {
    navigate('/contact');
  };
  
  // Scroll to the section when the page is loaded or route changes
  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: "auto" });
      }
    }
  }, [location]);

  return (
    <div className="product-page" id="main">
      <div className="product-transparent-box">
        <div className="hero-section">
          <h1>{t('integration.welcome')}</h1>
          <p>{t('integration.discover')}</p>
        </div>

        <div className="usbl-info-container">
          <section className="usbl-section">
            <h2>{t('integration.tailored.title')}</h2>
            <p>{t('integration.tailored.text_p1')}</p>
            <p>{t('integration.tailored.text_p2')}</p>
          </section>
        </div>

        <div className="usbl-info-container">
          <section className="usbl-section">
            <h2>{t('integration.custom.title')}</h2>
            <p>{t('integration.custom.text_p1')}</p>
            <p><strong>{t('integration.custom.c1.name')}</strong> {t('integration.custom.c1.description')}</p>
            <p><strong>{t('integration.custom.c2.name')}</strong> {t('integration.custom.c2.description')}</p>
          </section>
        </div>

        <div className="cta-section">
          <h2>{t('integration.footer.title')}</h2>
          <p>{t('integration.footer.intro')}</p>
          <button onClick={exploreMore}> {t('products.footer.button')} </button>
        </div>
      </div>
    </div>
  );
}
