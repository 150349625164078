import React from "react";
import "./ProductCardsModems.css";
import { Link } from "react-router-dom"; 
import '../i18n';
import { useTranslation } from 'react-i18next';

import Spin12_USBL from './product_cards/Spin12_USBL'
import Spin21_USBL from './product_cards/Spin21_USBL'
import Spin26H_USBL from './product_cards/Spin26H_USBL'
import Spin26_USBL from './product_cards/Spin26_USBL'
import Spin57_USBL from './product_cards/Spin57_USBL'

const ProductCardsModems = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <div className="Products">
      <div class="card-list-modems">
        <Spin12_USBL />
        <Spin21_USBL />
        <Spin26_USBL />
        <Spin26H_USBL />
        <Spin57_USBL />
      </div>
    </div>
  );
};
export default ProductCardsModems;