import React from "react";
import "./ProductCardsModems.css";
import { Link } from "react-router-dom"; 
import '../i18n';
import { useTranslation } from 'react-i18next';

import Spin12_MOBILE from './product_cards/Spin12_MOBILE'
import Spin21_MOBILE from './product_cards/Spin21_MOBILE'
import Spin26_MOBILE from './product_cards/Spin26_MOBILE'
import Spin26H_MOBILE from './product_cards/Spin26H_MOBILE'
import Spin57_MOBILE from './product_cards/Spin57_MOBILE'

const ProductCardsModems = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <div className="Products">
      <div class="card-list-modems">
        <Spin12_MOBILE />
        <Spin21_MOBILE />
        <Spin26_MOBILE />
        <Spin26H_MOBILE />
        <Spin57_MOBILE />

      </div>
    </div>
  );
};
export default ProductCardsModems;