import React from "react";
import "./ProductCardsModems.css";
import { Link } from "react-router-dom"; 
import '../i18n';
import { useTranslation } from 'react-i18next';

import Spin26H_USBL from './product_cards/Spin26H_USBL'
import Spin26H_OEM from './product_cards/Spin26H_OEM'
import Spin26H_BEACON from './product_cards/Spin26H_BEACON'

import ImageSpin26HMobile from "../assets/images/modems/processed/transponder_mobile_spin26H.png"

const ProductCardsSystem3Example = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <div className="Products">
      <div class="card-list-modems">
        
        <Spin26H_USBL />
        <Spin26H_USBL />

        <Spin26H_OEM />
        <Spin26H_OEM />

        <Spin26H_BEACON />
        <Spin26H_BEACON />

      </div>
    </div>
  );
};
export default ProductCardsSystem3Example;