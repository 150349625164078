import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom"; 

import './Products.css';
import './USBLProduct.css';
import '../ProductCards.css';
import "../ProductCardsModems.css";

import { useTranslation } from 'react-i18next';

export default function Career () {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  // Scroll to the section when the page is loaded or route changes
  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: "auto" });
      }
    }
  }, [location]);

  return (
    <div className="product-page" id="main">
      <div className="product-transparent-box">
        <div className="hero-section">
          <h1>{t('career.welcome')}</h1>
          <p>{t('career.discover')}</p>
        </div>

        <div className="usbl-info-container">
          <section className="usbl-section">
            <p>{t('career.text_p1')}</p>
            <p>{t('career.text_p2')}</p>
            <p>{t('career.text_p3')}</p>
          </section>

          <div className="Products">
            <div class="card-list-modems">
              
            <Link to="/career#job_3" className="card-link-wrapper"> {/* Wrap card with Link */}
              <div className="career">
                <div className="card-content">
                  <h3 className="card-title">{t('career.job_3.title')}</h3>
                </div>
              </div>
            </Link>
            <Link to="/career#job_1" className="card-link-wrapper"> {/* Wrap card with Link */}
              <div className="career">
                <div className="card-content">
                  <h3 className="card-title">{t('career.job_1.title')}</h3>
                </div>
              </div>
            </Link>
            <Link to="/career#job_2" className="card-link-wrapper"> {/* Wrap card with Link */}
              <div className="career">
                <div className="card-content">
                  <h3 className="card-title">{t('career.job_2.title')}</h3>
                </div>
              </div>
            </Link>

            </div>
          </div>


          


          <section className="usbl-section" id="job_3">
            <h2>{t('career.job_3.title')}</h2>
            <p><strong>{t('career.job_3.text_p1')}</strong></p>
            <div class="top-seller-description">
              <ul class="features-list">
                <li> {t('career.job_3.c1')}</li>
                <li> {t('career.job_3.c2')}</li>
                <li> {t('career.job_3.c3')}</li>
                <li> {t('career.job_3.c4')}</li>
                <li> {t('career.job_3.c5')}</li>
              </ul>
            </div>
            <p><strong>{t('career.job_3.text_p2')}</strong></p>
            <div class="top-seller-description">
              <ul class="features-list">
                <li> {t('career.job_3.b1')}</li>
                <li> {t('career.job_3.b2')}</li>
                <li> {t('career.job_3.b3')}</li>
                <li> {t('career.job_3.b4')}</li>
                <li> {t('career.job_3.b5')}</li>
                <li> {t('career.job_3.b6')}</li>
              </ul>
            </div>
            <p><strong>{t('career.job_3.text_p3')}</strong></p>
            <p><strong>{t('career.job_3.text_p4')}</strong></p>
            <p><strong>{t('career.job_3.text_p5')}</strong></p>
          </section>

          <section className="usbl-section" id="job_1">
            <h2>{t('career.job_1.title')}</h2>
            <p><strong>{t('career.job_1.text_p1')}</strong></p>
            <div class="top-seller-description">
              <ul class="features-list">
                <li> {t('career.job_1.c1')}</li>
                <li> {t('career.job_1.c2')}</li>
                <li> {t('career.job_1.c3')}</li>
                <li> {t('career.job_1.c4')}</li>
                <li> {t('career.job_1.c5')}</li>
              </ul>
            </div>
            <p><strong>{t('career.job_1.text_p2')}</strong></p>
            <div class="top-seller-description">
              <ul class="features-list">
                <li> {t('career.job_1.b1')}</li>
                <li> {t('career.job_1.b2')}</li>
                <li> {t('career.job_1.b3')}</li>
                <li> {t('career.job_1.b4')}</li>
              </ul>
            </div>
            <p><strong>{t('career.job_1.text_p3')}</strong></p>
            <p><strong>{t('career.job_1.text_p4')}</strong></p>
            <p><strong>{t('career.job_1.text_p5')}</strong></p>
          </section>

          <section className="usbl-section" id="job_2">
            <h2>{t('career.job_2.title')}</h2>
            <p><strong>{t('career.job_2.text_p1')}</strong></p>
            <div class="top-seller-description">
              <ul class="features-list">
                <li> {t('career.job_2.c1')}</li>
                <li> {t('career.job_2.c2')}</li>
                <li> {t('career.job_2.c3')}</li>
                <li> {t('career.job_2.c4')}</li>
                <li> {t('career.job_2.c5')}</li>
              </ul>
            </div>
            <p><strong>{t('career.job_2.text_p2')}</strong></p>
            <div class="top-seller-description">
              <ul class="features-list">
                <li> {t('career.job_2.b1')}</li>
                <li> {t('career.job_2.b2')}</li>
                <li> {t('career.job_2.b3')}</li>
                <li> {t('career.job_2.b4')}</li>
                <li> {t('career.job_2.b5')}</li>
                <li> {t('career.job_2.b6')}</li>
              </ul>
            </div>
            <p><strong>{t('career.job_2.text_p3')}</strong></p>
            <p><strong>{t('career.job_2.text_p4')}</strong></p>
            <p><strong>{t('career.job_2.text_p5')}</strong></p>
          </section>

        </div>
        
        <div className="cta-section">
          <h2>{t('career.footer.title')}</h2>
          <p>{t('career.footer.intro')}</p>
        </div>
      </div>
    </div>
  );
}
