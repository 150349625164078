import React from 'react';
import './ModemDetails.css';
import '../../i18n';
import { useTranslation } from 'react-i18next';

import ImageSpin12 from "../../assets/images/modems/processed/transponder_mobile_spin12.png"

const Spin12_MOBILE_SPEC = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="spec-container">
      <div className="spec-header-image">
        <img src={ImageSpin12} className="spec-header-image-img" />
      </div>

      <div className="spec-item full-width">
        <h2 className="spec-title">{t('spin12_mobile_spec.details.title')}</h2>
        <ul className="spec-details">
          <li>{t('spin12_mobile_spec.details.p1')}</li>
          <li>{t('spin12_mobile_spec.details.p2')}</li>
          <li>{t('spin12_mobile_spec.details.p3')}</li>
          <li>{t('spin12_mobile_spec.details.p4')}</li>
          <li>{t('spin12_mobile_spec.details.p5')}</li>
          <li>{t('spin12_mobile_spec.details.p6')}</li>
          <li>{t('spin12_mobile_spec.details.p7')}</li>
        </ul>
      </div>

      <div className="spec-items-row">
        <div className="spec-item half-width-50">
          <h2 className="spec-title">{t('spin12_mobile_spec.housing.title')}</h2>
          <ul className="spec-details">
            <li>{t('spin12_mobile_spec.housing.p1')}</li>
            <li>{t('spin12_mobile_spec.housing.p2')}</li>
            <li>{t('spin12_mobile_spec.housing.p3')}</li>
          </ul>
        </div>

        <div className="spec-item half-width-50">
          <h2 className="spec-title">{t('spin12_mobile_spec.interface.title')}</h2>
          <ul className="spec-details">
            <li>{t('spin12_mobile_spec.interface.p1')}</li>
            <li>{t('spin12_mobile_spec.interface.p2')}</li>
            <li>{t('spin12_mobile_spec.interface.p3')}</li>
            <li>{t('spin12_mobile_spec.interface.p4')}</li>
          </ul>
        </div>


      </div>
    </div>
  );
};

export default Spin12_MOBILE_SPEC;
