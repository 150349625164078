import React from "react";
import "../ProductCardsModems.css";
import { Link } from "react-router-dom"; 
import '../../i18n';
import { useTranslation } from 'react-i18next';

import ImageSpin26HOEM from "../../assets/images/modems/processed/spin_26H_oem.png"

const Spin26H_OEM = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <Link to="/acoustic_modems#spin26H_oem_spec" className="card-link-wrapper">
      <div class="card">
        <img src={ImageSpin26HOEM} alt={t('products.types.communcation_26H_OEM.title')} class="card-image" />
        <div class="card-content">
          <h3 class="card-title">{t('products.types.communcation_26H_OEM.title')}</h3>
          <p className="card-description">
            <ul class="features-list">
              <li>{t('products.types.communcation_26H_OEM.li_1')}</li>
              <li>{t('products.types.communcation_26H_OEM.li_2')}</li>
              <li>{t('products.types.communcation_26H_OEM.li_3')}</li>
              <li>{t('products.types.communcation_26H_OEM.li_4')}</li>
            </ul>
          </p>
        </div>
      </div>
    </Link>

  );
};
export default Spin26H_OEM;