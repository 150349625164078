import React from "react";
import "../ProductCardsModems.css";
import { Link } from "react-router-dom"; 
import '../../i18n';
import { useTranslation } from 'react-i18next';

import ImageSpin57 from "../../assets/images/modems/processed/spin_57.png"

const Spin26H = () => {
  const { t, i18n } = useTranslation();
  
  return (
     <Link to="/acoustic_modems#spin57_spec" className="card-link-wrapper">
          <div class="card">
            <img src={ImageSpin57} alt={t('products.types.communcation_57.title')} class="card-image" />
            <div class="card-content">
              <h3 class="card-title">{t('products.types.communcation_57.title')}</h3>
              <p className="card-description">
                <ul class="features-list">
                  <li>{t('products.types.communcation_57.li_1')}</li>
                  <li>{t('products.types.communcation_57.li_2')}</li>
                  <li>{t('products.types.communcation_57.li_3')}</li>
                </ul>
              </p>
            </div>
          </div>
        </Link>
  );
};
export default Spin26H;