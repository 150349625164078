import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import './Products.css';
import './USBLProduct.css';
import '../ProductCards.css';
import { useTranslation } from 'react-i18next';

import ImagePositioning from "../../assets/images/positioning_schema.png"
import ProductCardsUSBLModems from "../ProductCardsUSBLModems.js"

import Spin57_USBL_SPEC from '../product_spec/Spin57_USBL_SPEC'
import Spin26H_USBL_SPEC from '../product_spec/Spin26H_USBL_SPEC'
import Spin26_USBL_SPEC from '../product_spec/Spin26_USBL_SPEC'
import Spin12_USBL_SPEC from '../product_spec/Spin12_USBL_SPEC'
import Spin21_USBL_SPEC from '../product_spec/Spin21_USBL_SPEC'

export default function USBLProduct() {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const navigate = useNavigate();

  const exploreMore = () => {
    navigate('/contact');
  };
  
  // Scroll to the section when the page is loaded or route changes
  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: "auto" });
      }
    }
  }, [location]);

  return (
    <div className="product-page" id="main">
      <div className="product-transparent-box">
        <div className="hero-section">
          <h1>{t('products_usbl.welcome')}</h1>
          <p>{t('products_usbl.discover')}</p>
        </div>

        <div className="usbl-info-container">
          <section className="usbl-section">
            <h2>{t('products_usbl.principle.title')}</h2>
            <p>{t('products_usbl.principle.text_p1')}</p>
            <p>{t('products_usbl.principle.text_p2')}</p>
          </section>

          <section className="usbl-section">
            <h2>{t('products_usbl.components.title')}</h2>
            <div className="usbl-content">
              <div className="usbl-text">
                <p><strong>{t('products_usbl.components.c1.name')}</strong> {t('products_usbl.components.c1.description')}</p>
                <p><strong>{t('products_usbl.components.c2.name')}</strong> {t('products_usbl.components.c2.description')}</p>
                <p><strong>{t('products_usbl.components.c3.name')}</strong> {t('products_usbl.components.c3.description')}</p>
              </div>
              <div className="usbl-image">
                <img src={ImagePositioning} className="pos-image" alt="USBL Positioning" />
              </div>
            </div>
          </section>

          <section className="usbl-section">
            <h2>{t('products_usbl.mechanism.title')}</h2>
            <p>{t('products_usbl.mechanism.description')}</p>
            <ol>
              <li><strong>{t('products_usbl.mechanism.c1.name')}</strong>{t('products_usbl.mechanism.c1.description')}</li>
              <li><strong>{t('products_usbl.mechanism.c2.name')}</strong>{t('products_usbl.mechanism.c2.description')}</li>
              <li><strong>{t('products_usbl.mechanism.c3.name')}</strong>{t('products_usbl.mechanism.c3.description')}</li>
              <li><strong>{t('products_usbl.mechanism.c4.name')}</strong>{t('products_usbl.mechanism.c4.description')}</li>
              <li><strong>{t('products_usbl.mechanism.c5.name')}</strong>{t('products_usbl.mechanism.c5.description')}</li>
            </ol>
          </section>

          <section className="usbl-section">
            <h2>{t('products_usbl.application.title')}</h2>
            <p><strong>{t('products_usbl.application.c1.name')}</strong> {t('products_usbl.application.c1.description')}</p>
            <p><strong>{t('products_usbl.application.c2.name')}</strong> {t('products_usbl.application.c2.description')}</p>
            <p><strong>{t('products_usbl.application.c3.name')}</strong> {t('products_usbl.application.c3.description')}</p>
            <p><strong>{t('products_usbl.application.c4.name')}</strong> {t('products_usbl.application.c4.description')}</p>
            <p><strong>{t('products_usbl.application.c5.name')}</strong> {t('products_usbl.application.c5.description')}</p>
          </section>

          <section className="usbl-section">
            <h2>{t('products_usbl.types.title')}</h2>
            <p> {t('products_usbl.types.description.p1')} </p>
            <p> {t('products_usbl.types.description.p2')} </p>
          </section>

          <div>  <ProductCardsUSBLModems /> </div>

          <section className="usbl-section">
            <h2 id="spin12_usbl_spec">{t('spin12_usbl_spec.title')}</h2>
            <div>  <Spin12_USBL_SPEC /> </div>
          </section>

          <section className="usbl-section">
            <h2 id="spin21_usbl_spec">{t('spin21_usbl_spec.title')}</h2>
            <div>  <Spin21_USBL_SPEC /> </div>
          </section>


          <section className="usbl-section">
            <h2 id="spin26_usbl_spec">{t('spin26_usbl_spec.title')}</h2>
            <div>  <Spin26_USBL_SPEC /> </div>
          </section>

          <section className="usbl-section">
            <h2 id="spin26H_usbl_spec">{t('spin26H_usbl_spec.title')}</h2>
            <div>  <Spin26H_USBL_SPEC /> </div>
          </section>

          <section className="usbl-section">
            <h2 id="spin57_usbl_spec">{t('spin57_usbl_spec.title')}</h2>
            <div>  <Spin57_USBL_SPEC /> </div>
          </section>

        </div>

        <div className="cta-section">
          <h2>{t('products_usbl.footer.title')}</h2>
          <p>{t('products_usbl.footer.intro')}</p>
          <button onClick={exploreMore}> {t('products.footer.button')} </button>
        </div>
      </div>
    </div>
  );
}
