import React from "react";
import "./ProductCards.css";
import { Link } from "react-router-dom"; 
import '../i18n';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <footer class="footer">
      <div class="footer-container">
        <div class="footer-contact">
          <p>
            {t('footer.address')}
            <a href="mailto:sales@latena.ru">sales@latena.ru  </a>
             +7 (911) 777-80-01
          </p>
        </div>
        <div class="footer-copyright">
          <p>&copy; {t('footer.copyright')}</p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;