import React from "react";
import "../ProductCardsModems.css";
import { Link } from "react-router-dom"; 
import '../../i18n';
import { useTranslation } from 'react-i18next';

import ImageSpin21 from "../../assets/images/modems/processed/spin_21_oem.png"

const Spin21_OEM = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <Link to="/acoustic_modems#spin21_oem_spec" className="card-link-wrapper">
      <div className="card">
          <img src={ImageSpin21} alt={t('products.types.communcation_21_OEM.title')} className="card-image" />
          <div className="card-content">
            <h3 className="card-title">{t('products.types.communcation_21_OEM.title')}</h3>
            <p className="card-description">
              <ul class="features-list">
                <li>{t('products.types.communcation_21_OEM.li_1')}</li>
                <li>{t('products.types.communcation_21_OEM.li_2')}</li>
                <li>{t('products.types.communcation_21_OEM.li_3')}</li>
                <li>{t('products.types.communcation_21_OEM.li_4')}</li>
              </ul>
            </p>
          </div>
        </div>
      </Link>

  );
};
export default Spin21_OEM;