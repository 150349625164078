import React from 'react';
import '../../App.css';
import ProductCards  from "../ProductCards";
import ImageSlider  from "../ImageSlider";
import { Router } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../i18n';
import './Pages.css';

export default function Home() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div>
        <ImageSlider interval={8000} />
        
        <div class="welcome-section">
          <div class="container">
              <h1 class="title">
                {t('home.welcome')}
              </h1>
              
              <p class="intro">
                {t('home.team')}
              </p>

              <p class="call-to-action">
                {t('home.footer')}
              </p>

              <p class="call-to-contact">
                {t('home.contactUS')}
              </p>

              <hr/>
            
              <h2 class="subtitle">{t('home.activity')}</h2>
                
          </div>
        </div>

        <div><ProductCards /></div>
        
        <div class="welcome-section">
          <div class="container">
              
          </div>
        </div>
      </div>
    </>
  );
}
