import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import './Products.css';
import './USBLProduct.css';
import '../ProductCards.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Lanas_RU from "../../assets/images/lanas-ru.png"
import Lanas_EN from "../../assets/images/lanas-en.png"

const image = Lanas_EN;

export default function Software() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [currentImage, setCurrentImage] = useState(image);

  const navigate = useNavigate();

  const exploreMore = () => {
    navigate('/contact');
  };
  
  // Subscribe language change event
  useEffect(() => {
    const handleLanguageChange = (lng) => {
      switch(lng) {
        case 'ru' :
            setCurrentImage(Lanas_RU);
            break;
        default :
            setCurrentImage(Lanas_EN);
            break;
      }
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  // Scroll to the section when the page is loaded or route changes
  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: "auto" });
      }
    }
  }, [location]);

  return (
    <div className="product-page" id="main">
      <div className="product-transparent-box">
        <div className="hero-section">
          <h1>{t('software.welcome')}</h1>
          <p>{t('software.discover')}</p>
        </div>

        <div className="usbl-info-container">
          <section className="usbl-section">
            <h2>{t('software.principle.title')}</h2>
            <p>{t('software.principle.text_p1')}</p>
            <p>{t('software.principle.text_p2')}</p>
          </section>
        </div>

        <div className="usbl-info-container">
          <section className="usbl-section">
            <h2>{t('software.lanas.title')}</h2>

            <img src={currentImage} alt={t('home.activities.acoustic_title')} className="lanas-image" />

            <p>{t('software.lanas.text_p1')}</p>

            <p><strong>{t('software.lanas.c1.name')}</strong> {t('software.lanas.c1.description')}</p>
            <p><strong>{t('software.lanas.c2.name')}</strong> {t('software.lanas.c2.description')}</p>
            <p><strong>{t('software.lanas.c3.name')}</strong> {t('software.lanas.c3.description')}</p>
            <p><strong>{t('software.lanas.c4.name')}</strong> {t('software.lanas.c4.description')}</p>
            <p><strong>{t('software.lanas.c5.name')}</strong> {t('software.lanas.c5.description')}</p>
          </section>
          
          
        </div>
        
        <div className="cta-section">
          <h2>{t('software.footer.title')}</h2>
          <p>{t('software.footer.intro')}</p>
          <button onClick={exploreMore}> {t('products.footer.button')} </button>
        </div>
      </div>
    </div>
  );
}
