// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation JSON files
import translationEN from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';

// Define resources, mapping each language to its translation file
const resources = {
  en: { translation: translationEN },
  ru: { translation: translationRU },
};

i18n
  .use(initReactI18next)
  .init({
    resources,           // This is where the files are linked
    lng: 'en',           // Set default language
    fallbackLng: 'en',   // Set fallback language if none is selected
    interpolation: {
      escapeValue: false, // React already escapes strings, so we don't need to here
    },
  });

export default i18n;