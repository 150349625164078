import React from "react";
import "../ProductCardsModems.css";
import { Link } from "react-router-dom"; 
import '../../i18n';
import { useTranslation } from 'react-i18next';

import ImageSpin57Compact from "../../assets/images/modems/processed/compact_spin57.png"

const Spin57_COMPACT = () => {
  const { t, i18n } = useTranslation();
  
  return (
     <Link to="/acoustic_modems#spin57_compact_spec" className="card-link-wrapper">
        <div class="card">
          <img src={ImageSpin57Compact} alt={t('products.types.communcation_57_COMPACT.title')} class="card-image" />
          <div class="card-content">
            <h3 class="card-title">{t('products.types.communcation_57_COMPACT.title')}</h3>
            <p className="card-description">
              <ul class="features-list">
                <li>{t('products.types.communcation_57_COMPACT.li_1')}</li>
                <li>{t('products.types.communcation_57_COMPACT.li_2')}</li>
                <li>{t('products.types.communcation_57_COMPACT.li_3')}</li>
              </ul>
            </p>
          </div>
        </div>
      </Link>
  );
};
export default Spin57_COMPACT;