import React from "react";
import "./ProductCards.css";
import { Link } from "react-router-dom"; 
import '../i18n';
import { useTranslation } from 'react-i18next';

import ImageUSBL from "../assets/images/modems/processed/USBL1834H-4.png"
import ImageMiniModem from "../assets/images/modems/processed/mini_modem_1.png"
import ImageOEM from "../assets/images/modems/processed/oem.png"
import ImageAPI from "../assets/images/modems/processed/api.png"
import ImageUsability from "../assets/images/modems/processed/usability.jpeg"

const ProductCards = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <div className="Products">
      <div class="card-list">
      
        <Link to="/usbl-product#main" className="card-link-wrapper"> {/* Wrap card with Link */}
          <div className="card">
            <img src={ImageUSBL} alt={t('home.activities.positioning_title')} className="card-image" />
            <div className="card-content">
              <h3 className="card-title">{t('home.activities.positioning_title')}</h3>
              <p className="card-description">{t('home.activities.positioning')}</p>
            </div>
          </div>
        </Link>

        <Link to="/acoustic_modems#main" className="card-link-wrapper">
        <div className="card">
            <img src={ImageMiniModem} alt={t('home.activities.acoustic_title')} className="card-image" />
            <div className="card-content">
              <h3 className="card-title">{t('home.activities.acoustic_title')}</h3>
              <p className="card-description">{t('home.activities.acoustic')}</p>
            </div>
          </div>
        </Link>

        <Link to="/accessoires#main" className="card-link-wrapper">
          <div class="card">
            <img src={ImageOEM} alt={t('home.activities.customization_title')} class="card-image" />
            <div class="card-content">
              <h3 class="card-title">{t('home.activities.customization_title')}</h3>
              <p class="card-description">{t('home.activities.customization')}</p>
            </div>
          </div>
        </Link>

        <Link to="/software#main" className="card-link-wrapper">
          <div class="card">
            <img src={ImageUsability} alt={t('home.activities.usability_title')} class="card-image" />
            <div class="card-content">
              <h3 class="card-title">{t('home.activities.usability_title')}</h3>
              <p class="card-description">{t('home.activities.usability')}</p>
            </div>
          </div>
        </Link>

        <Link to="/integration#main" className="card-link-wrapper">
          <div class="card">
            <img src={ImageAPI} alt={t('home.activities.integration_title')} class="card-image" />
            <div class="card-content">
              <h3 class="card-title">{t('home.activities.integration_title')}</h3>
              <p class="card-description">{t('home.activities.integration')}</p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
export default ProductCards;