import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ProductCardsModems from '../ProductCardsModems.js';
import ProductCardsCompacts from '../ProductCardsCompacts.js';
import ProductCardsUSBLModems from '../ProductCardsUSBLModems.js';
import ProductCardsBeacons from '../ProductCardsBeacons.js';
import ProductCardsOEMModems from '../ProductCardsOEMModems.js';
import ProductCardsBeaconsMobile from '../ProductCardsBeaconsMobile.js';
import ProductCardsAccessoires from '../ProductCardsAccessoires.js';

import ProductCardsSystem1Example from '../ProductCardsSystem1Example.js';
import ProductCardsSystem2Example from '../ProductCardsSystem2Example.js';
import ProductCardsSystem3Example from '../ProductCardsSystem3Example.js';
import ProductCardsSystem4Example from '../ProductCardsSystem4Example.js';

import './Products.css';
import '../ProductCards.css';
import { Link } from "react-router-dom"; 
import '../../i18n';
import { useTranslation } from 'react-i18next';

export default function Products() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const exploreMore = () => {
    navigate('/contact');
  };
  
  const [isOpenSystem1Basic, setIsOpenSystem1Basic] = useState(false);
  const [isOpenSystem2Basic, setIsOpenSystem2Basic] = useState(false);
  const [isOpenSystem3Basic, setIsOpenSystem3Basic] = useState(false);
  const [isOpenSystem4Basic, setIsOpenSystem4Basic] = useState(false);

  const [isOpenSystem1Compact, setIsOpenSystem1Compact] = useState(false);
  const [isOpenSystem2Compact, setIsOpenSystem2Compact] = useState(false);
  const [isOpenSystem3Compact, setIsOpenSystem3Compact] = useState(false);
  const [isOpenSystem4Compact, setIsOpenSystem4Compact] = useState(false);

  const [isOpenSystem1OEM, setIsOpenSystem1OEM] = useState(false);
  const [isOpenSystem2OEM, setIsOpenSystem2OEM] = useState(false);
  const [isOpenSystem3OEM, setIsOpenSystem3OEM] = useState(false);
  const [isOpenSystem4OEM, setIsOpenSystem4OEM] = useState(false);

  const [isOpenSystem3Accessoires, setIsOpenSystem3Accessoires] = useState(false);
  const [isOpenSystem4Accessoires, setIsOpenSystem4Accessoires] = useState(false);

  const [isOpenSystem2USBL, setIsOpenSystem2USBL] = useState(false);
  const [isOpenSystem4USBL, setIsOpenSystem4USBL] = useState(false);

  const [isOpenSystem3Depth, setIsOpenSystem3Depth] = useState(false);
  const [isOpenSystem4Depth, setIsOpenSystem4Depth] = useState(false);

  const [isOpenSystem3Mobile, setIsOpenSystem3Mobile] = useState(false);
  const [isOpenSystem4Mobile, setIsOpenSystem4Mobile] = useState(false);

  const [contentHeightSystem1Basic, setContentHeightSystem1Basic] = useState(0);
  const [contentHeightSystem2Basic, setContentHeightSystem2Basic] = useState(0);
  const [contentHeightSystem3Basic, setContentHeightSystem3Basic] = useState(0);
  const [contentHeightSystem4Basic, setContentHeightSystem4Basic] = useState(0);

  const [contentHeightSystem1Compact, setContentHeightSystem1Compact] = useState(0);
  const [contentHeightSystem2Compact, setContentHeightSystem2Compact] = useState(0);
  const [contentHeightSystem3Compact, setContentHeightSystem3Compact] = useState(0);
  const [contentHeightSystem4Compact, setContentHeightSystem4Compact] = useState(0);

  const [contentHeightSystem1OEM, setContentHeightSystem1OEM] = useState(0);
  const [contentHeightSystem2OEM, setContentHeightSystem2OEM] = useState(0);
  const [contentHeightSystem3OEM, setContentHeightSystem3OEM] = useState(0);
  const [contentHeightSystem4OEM, setContentHeightSystem4OEM] = useState(0);

  const [contentHeightSystem3Accessoires, setContentHeightSystem3Accessoires] = useState(0);
  const [contentHeightSystem4Accessoires, setContentHeightSystem4Accessoires] = useState(0);

  const [contentHeightSystem2USBL, setContentHeightSystem2USBL] = useState(0);
  const [contentHeightSystem4USBL, setContentHeightSystem4USBL] = useState(0);

  const [contentHeightSystem3Depth, setContentHeightSystem3Depth] = useState(0);
  const [contentHeightSystem4Depth, setContentHeightSystem4Depth] = useState(0);

  const [contentHeightSystem3Mobile, setContentHeightSystem3Mobile] = useState(0);
  const [contentHeightSystem4Mobile, setContentHeightSystem4Mobile] = useState(0);

  const contentRefSystem1Basic = useRef(null);
  const contentRefSystem2Basic = useRef(null);
  const contentRefSystem3Basic = useRef(null);
  const contentRefSystem4Basic = useRef(null);

  const contentRefSystem1Compact = useRef(null);
  const contentRefSystem2Compact = useRef(null);
  const contentRefSystem3Compact = useRef(null);
  const contentRefSystem4Compact = useRef(null);

  const contentRefSystem1OEM = useRef(null);
  const contentRefSystem2OEM = useRef(null);
  const contentRefSystem3OEM = useRef(null);
  const contentRefSystem4OEM = useRef(null);

  const contentRefSystem3Accessoires = useRef(null);
  const contentRefSystem4Accessoires = useRef(null);

  const contentRefSystem2USBL = useRef(null);
  const contentRefSystem4USBL = useRef(null);

  const contentRefSystem3Depth = useRef(null);
  const contentRefSystem4Depth = useRef(null);
  
  const contentRefSystem3Mobile = useRef(null);
  const contentRefSystem4Mobile = useRef(null);

  // Set content height dynamically for smooth expansion
  useEffect(() => {
    if (contentRefSystem1Basic.current) {
      setContentHeightSystem1Basic(contentRefSystem1Basic.current.scrollHeight);
    }

    if (contentRefSystem2Basic.current) {
      setContentHeightSystem2Basic(contentRefSystem2Basic.current.scrollHeight);
    }

    if (contentRefSystem3Basic.current) {
      setContentHeightSystem3Basic(contentRefSystem3Basic.current.scrollHeight);
    }

    if (contentRefSystem4Basic.current) {
      setContentHeightSystem4Basic(contentRefSystem4Basic.current.scrollHeight);
    }
  }, [isOpenSystem1Basic, isOpenSystem2Basic, isOpenSystem3Basic, isOpenSystem4Basic]);

  
  useEffect(() => {
    if (contentRefSystem1OEM.current) {
      setContentHeightSystem1OEM(contentRefSystem1OEM.current.scrollHeight);
    }

    if (contentRefSystem2OEM.current) {
      setContentHeightSystem2OEM(contentRefSystem2OEM.current.scrollHeight);
    }

    if (contentRefSystem3OEM.current) {
      setContentHeightSystem3OEM(contentRefSystem3OEM.current.scrollHeight);
    }

    if (contentRefSystem4OEM.current) {
      setContentHeightSystem4OEM(contentRefSystem4OEM.current.scrollHeight);
    }
  }, [isOpenSystem1OEM, isOpenSystem2OEM, isOpenSystem3OEM, isOpenSystem4OEM]);


  useEffect(() => {
    if (contentRefSystem2USBL.current) {
      setContentHeightSystem2USBL(contentRefSystem2USBL.current.scrollHeight);
    }

    if (contentRefSystem4USBL.current) {
      setContentHeightSystem4USBL(contentRefSystem4USBL.current.scrollHeight);
    }
  }, [isOpenSystem2USBL, isOpenSystem4USBL]);

  useEffect(() => {
    if (contentRefSystem3Accessoires.current) {
      setContentHeightSystem3Accessoires(contentRefSystem3Accessoires.current.scrollHeight);
    }

    if (contentRefSystem4Accessoires.current) {
      setContentHeightSystem4Accessoires(contentRefSystem4Accessoires.current.scrollHeight);
    }
  }, [isOpenSystem3Accessoires, isOpenSystem4Accessoires]);

  useEffect(() => {
    if (contentRefSystem1Compact.current) {
      setContentHeightSystem1Compact(contentRefSystem1Compact.current.scrollHeight);
    }

    if (contentRefSystem2Compact.current) {
      setContentHeightSystem2Compact(contentRefSystem2Compact.current.scrollHeight);
    }

    if (contentRefSystem3Compact.current) {
      setContentHeightSystem3Compact(contentRefSystem3Compact.current.scrollHeight);
    }

    if (contentRefSystem4Compact.current) {
      setContentHeightSystem4Compact(contentRefSystem4Compact.current.scrollHeight);
    }

  }, [isOpenSystem1Compact, isOpenSystem2Compact, isOpenSystem3Compact, isOpenSystem4Compact]);

  useEffect(() => {
    if (contentRefSystem3Depth.current) {
      setContentHeightSystem3Depth(contentRefSystem3Depth.current.scrollHeight);
    }

    if (contentRefSystem3Mobile.current) {
      setContentHeightSystem3Mobile(contentRefSystem3Mobile.current.scrollHeight);
    }

    if (contentRefSystem4Depth.current) {
      setContentHeightSystem4Depth(contentRefSystem4Depth.current.scrollHeight);
    }

    if (contentRefSystem4Mobile.current) {
      setContentHeightSystem4Mobile(contentRefSystem4Mobile.current.scrollHeight);
    }
  }, [isOpenSystem3Depth, isOpenSystem3Mobile, isOpenSystem4Depth, isOpenSystem4Mobile]);

  return (
  <div class="product-page">
    <div className="product-transparent-box">
        <div class="hero-section">
            <h1>{t('products.welcome')}</h1>
            <p>{t('products.discover')}</p>
        </div>

        <div class="card-list">
          <div class="top-seller-card">
            <h3 class="top-seller-title">{t('products.seller_cards.card_1.title')}</h3>
            <p class="top-seller-description">{t('products.seller_cards.card_1.description')}</p>
            <a href="#basic-communication" class="top-seller-button">{t('products.seller_cards.button')}</a>
          </div>

          <div class="top-seller-card">
            <span class="top-seller-badge">{t('products.seller_cards.top_seller')}</span>
            <h3 class="top-seller-title">{t('products.seller_cards.card_2.title')}</h3>
            <p class="top-seller-description">{t('products.seller_cards.card_2.description')}</p>    
            <a href="#underwater-pos" class="top-seller-button">{t('products.seller_cards.button')}</a>
          </div>

          <div class="top-seller-card">
            <h3 class="top-seller-title">{t('products.seller_cards.card_3.title')}</h3>
            <p class="card-description">{t('products.seller_cards.card_3.description')}</p>
            <a href="#sensor-network" class="top-seller-button">{t('products.seller_cards.button')}</a>
          </div>

          <div class="top-seller-card">
            {/*<span class="sale-badge">{t('products.sale')}</span>*/}
            <h3 class="top-seller-title">{t('products.seller_cards.card_4.title')}</h3>
            <p class="top-seller-description">{t('products.seller_cards.card_4.description')}</p>
            <a href="#sensor-network-pos" class="top-seller-button">{t('products.seller_cards.button')}</a>
          </div>
        </div>

        <div class="welcome-section">
          <div class="container">
              
              <hr/>
              <p class="intro">
                {t('products.description_1')}
              </p>

              <p class="intro">
                {t('products.description_2')}
              </p>

              <p class="intro">
                {t('products.description_3')}
              </p>

          </div>
        </div>


        <div class="welcome-section">
          <div class="container-out">
          <div class="container">
              <h1 class="title" id="basic-communication">{t('products.seller_cards.card_1.title')}</h1>
              <p class="intro">{t('products.seller_cards.card_1.intro')}</p>
              <p class="intro">
                <div class="top-seller-description">
                  <ul class="features-list">
                    <li><strong>{t('products.seller_cards.card_1.bullet_points.p1.feature')}</strong>{t('products.seller_cards.card_1.bullet_points.p1.description')}</li>
                      <li><strong>{t('products.seller_cards.card_1.bullet_points.p2.feature')}</strong>{t('products.seller_cards.card_1.bullet_points.p2.description')}</li>
                      <li><strong>{t('products.seller_cards.card_1.bullet_points.p3.feature')}</strong>{t('products.seller_cards.card_1.bullet_points.p3.description')}</li>
                      <li><strong>{t('products.seller_cards.card_1.bullet_points.p4.feature')}</strong>{t('products.seller_cards.card_1.bullet_points.p4.description')}</li>
                      <li><strong>{t('products.seller_cards.card_1.bullet_points.p5.feature')}</strong>{t('products.seller_cards.card_1.bullet_points.p5.description')}</li>
                      <li><strong>{t('products.seller_cards.card_1.bullet_points.p6.feature')}</strong>{t('products.seller_cards.card_1.bullet_points.p6.description')}</li>
                      <li><strong>{t('products.seller_cards.card_1.bullet_points.p7.feature')}</strong>{t('products.seller_cards.card_1.bullet_points.p7.description')}</li>
                      <li><strong>{t('products.seller_cards.card_1.bullet_points.p8.feature')}</strong>{t('products.seller_cards.card_1.bullet_points.p8.description')}</li>
                  </ul>
                </div>
              </p>

              <hr class="short-separator-line"/>

              <h1 class="products-title">{t('products.system_example')}</h1>
              <p class="intro_p">{t('products.system1_description.p1')}</p>
              <p class="intro_p">{t('products.system1_description.p2')}</p>
              <p class="intro_p">{t('products.system1_description.p3')}</p>

              <div>  <ProductCardsSystem1Example /> </div>

              <hr class="short-separator-line"/>

              <h1 class="products-title">{t('products.required')}</h1>

              <div className="line-smooth-main">
                  <div className="line-smooth" onClick={() => setIsOpenSystem1Basic(!isOpenSystem1Basic)}>
                    <h2 class="line-smooth-title">{t('products.required_basic')}</h2>
                    <span className={`arrow-line-smooth ${isOpenSystem1Basic ? 'rotate' : ''}`}>&#9660;</span>
                  </div>
                  <div
                    className="content-wrapper-smooth"
                    style={{
                      height: isOpenSystem1Basic ? `${contentHeightSystem1Basic}px` : '0',
                      overflow: 'hidden',
                      transition: 'height 0.3s ease-in-out'
                    }}
                  >
                  <div ref={contentRefSystem1Basic}>  <ProductCardsModems /> </div>
                </div>
              </div>

              <div className="line-smooth-main">
                  <div className="line-smooth" onClick={() => setIsOpenSystem1Compact(!isOpenSystem1Compact)}>
                    <h2 class="line-smooth-title">{t('products.required_compact')}</h2>
                    <span className={`arrow-line-smooth ${isOpenSystem1Compact ? 'rotate' : ''}`}>&#9660;</span>
                  </div>
                  <div
                    className="content-wrapper-smooth"
                    style={{
                      height: isOpenSystem1Compact ? `${contentHeightSystem1Compact}px` : '0',
                      overflow: 'hidden',
                      transition: 'height 0.3s ease-in-out'
                    }}
                  >
                  <div ref={contentRefSystem1Compact}>  <ProductCardsCompacts /> </div>
                </div>
              </div>

              <div className="line-smooth-main">
                  <div className="line-smooth" onClick={() => setIsOpenSystem1OEM(!isOpenSystem1OEM)}>
                    <h2 class="line-smooth-title">{t('products.required_basic_oem')}</h2>
                    <span className={`arrow-line-smooth ${isOpenSystem1OEM ? 'rotate' : ''}`}>&#9660;</span>
                  </div>
                  <div
                    className="content-wrapper-smooth"
                    style={{
                      height: isOpenSystem1OEM ? `${contentHeightSystem1OEM}px` : '0',
                      overflow: 'hidden',
                      transition: 'height 0.3s ease-in-out'
                    }}
                  >
                  <div ref={contentRefSystem1OEM}>  <ProductCardsOEMModems /> </div>
                </div>
              </div>
              </div>
            </div>
          </div>

          
          <div class="welcome-section">
            <div class="container-out">
            <div class="container">    
             <h1 class="title" id="underwater-pos">{t('products.seller_cards.card_2.title')}</h1>
              <p class="intro">{t('products.seller_cards.card_2.intro')}</p>
              <p class="intro">
                <div class="top-seller-description">
                  <ul class="features-list">
                      <li><strong>{t('products.seller_cards.card_2.bullet_points.p1.feature')}</strong> {t('products.seller_cards.card_2.bullet_points.p1.description')}</li>
                      <li><strong>{t('products.seller_cards.card_2.bullet_points.p2.feature')}</strong> {t('products.seller_cards.card_2.bullet_points.p2.description')}</li>
                      <li><strong>{t('products.seller_cards.card_2.bullet_points.p3.feature')}</strong> {t('products.seller_cards.card_2.bullet_points.p3.description')}</li>
                      <li><strong>{t('products.seller_cards.card_2.bullet_points.p4.feature')}</strong> {t('products.seller_cards.card_2.bullet_points.p4.description')} </li>
                      <li><strong>{t('products.seller_cards.card_2.bullet_points.p5.feature')}</strong> {t('products.seller_cards.card_2.bullet_points.p5.description')} </li>
                      <li><strong>{t('products.seller_cards.card_2.bullet_points.p6.feature')}</strong> {t('products.seller_cards.card_2.bullet_points.p6.description')}</li>
                      <li><strong>{t('products.seller_cards.card_2.bullet_points.p7.feature')}</strong> {t('products.seller_cards.card_2.bullet_points.p7.description')} </li>
                      <li><strong>{t('products.seller_cards.card_2.bullet_points.p8.feature')}</strong> {t('products.seller_cards.card_2.bullet_points.p8.description')} </li>
                      <li><strong>{t('products.seller_cards.card_2.bullet_points.p9.feature')}</strong> {t('products.seller_cards.card_2.bullet_points.p9.description')} </li>
                  </ul>
                </div>
              </p>

              <hr class="short-separator-line"/>

              <h1 class="products-title">{t('products.system_example')}</h1>
              <p class="intro_p">{t('products.system2_description.p1')}</p>
              <p class="intro_p">{t('products.system2_description.p2')}</p>
              <p class="intro_p">{t('products.system2_description.p3')}</p>

              <div>  <ProductCardsSystem2Example /> </div>

              <hr class="short-separator-line"/>

              <h1 class="products-title">{t('products.required')}</h1>

              <div className="line-smooth-main">
                  <div className="line-smooth" onClick={() => setIsOpenSystem2USBL(!isOpenSystem2USBL)}>
                    <h2 class="line-smooth-title">{t('products.required_positioning')}</h2>
                    <span className={`arrow-line-smooth ${isOpenSystem2USBL ? 'rotate' : ''}`}>&#9660;</span>
                  </div>
                  <div
                    className="content-wrapper-smooth"
                    style={{
                      height: isOpenSystem2USBL ? `${contentHeightSystem2USBL}px` : '0',
                      overflow: 'hidden',
                      transition: 'height 0.3s ease-in-out'
                    }}
                  >
                  <div ref={contentRefSystem2USBL}>  <ProductCardsUSBLModems /> </div>
                </div>
              </div>

              <div className="line-smooth-main">
                  <div className="line-smooth" onClick={() => setIsOpenSystem2Basic(!isOpenSystem2Basic)}>
                    <h2 class="line-smooth-title">{t('products.required_basic')}</h2>
                    <span className={`arrow-line-smooth ${isOpenSystem2Basic ? 'rotate' : ''}`}>&#9660;</span>
                  </div>
                  <div
                    className="content-wrapper-smooth"
                    style={{
                      height: isOpenSystem2Basic ? `${contentHeightSystem2Basic}px` : '0',
                      overflow: 'hidden',
                      transition: 'height 0.3s ease-in-out'
                    }}
                  >
                  <div ref={contentRefSystem2Basic}>  <ProductCardsModems /> </div>
                </div>
              </div>


              <div className="line-smooth-main">
                  <div className="line-smooth" onClick={() => setIsOpenSystem2Compact(!isOpenSystem2Compact)}>
                    <h2 class="line-smooth-title">{t('products.required_compact')}</h2>
                    <span className={`arrow-line-smooth ${isOpenSystem2Compact ? 'rotate' : ''}`}>&#9660;</span>
                  </div>
                  <div
                    className="content-wrapper-smooth"
                    style={{
                      height: isOpenSystem2Compact ? `${contentHeightSystem2Compact}px` : '0',
                      overflow: 'hidden',
                      transition: 'height 0.3s ease-in-out'
                    }}
                  >
                  <div ref={contentRefSystem2Compact}>  <ProductCardsCompacts /> </div>
                </div>
              </div>


              <div className="line-smooth-main">
                  <div className="line-smooth" onClick={() => setIsOpenSystem2OEM(!isOpenSystem2OEM)}>
                    <h2 class="line-smooth-title">{t('products.required_basic_oem')}</h2>
                    <span className={`arrow-line-smooth ${isOpenSystem2OEM ? 'rotate' : ''}`}>&#9660;</span>
                  </div>
                  <div
                    className="content-wrapper-smooth"
                    style={{
                      height: isOpenSystem2OEM ? `${contentHeightSystem2OEM}px` : '0',
                      overflow: 'hidden',
                      transition: 'height 0.3s ease-in-out'
                    }}
                  >
                  <div ref={contentRefSystem2OEM}>  <ProductCardsOEMModems /> </div>
                </div>
              </div>

              </div>
              </div>
          </div>


          <div class="welcome-section">
            <div class="container-out">
            <div class="container">
              
              <h1 class="title" id="sensor-network">{t('products.seller_cards.card_3.title')}</h1>
              <p class="intro">{t('products.seller_cards.card_3.intro')}</p>
              <p class="intro">
                <div class="top-seller-description">
                  <ul class="sensor-network-features">
                    <li><strong>{t('products.seller_cards.card_3.bullet_points.p1.feature')}</strong>{t('products.seller_cards.card_3.bullet_points.p1.description')}</li>
                    <li><strong>{t('products.seller_cards.card_3.bullet_points.p2.feature')}</strong>{t('products.seller_cards.card_3.bullet_points.p2.description')}</li>
                    <li><strong>{t('products.seller_cards.card_3.bullet_points.p3.feature')}</strong>{t('products.seller_cards.card_3.bullet_points.p3.description')}</li>
                    <li><strong>{t('products.seller_cards.card_3.bullet_points.p4.feature')}</strong>{t('products.seller_cards.card_3.bullet_points.p4.description')}</li>
                    <li><strong>{t('products.seller_cards.card_3.bullet_points.p5.feature')}</strong>{t('products.seller_cards.card_3.bullet_points.p5.description')}</li>
                    <li><strong>{t('products.seller_cards.card_3.bullet_points.p6.feature')}</strong>{t('products.seller_cards.card_3.bullet_points.p6.description')}</li>
                  </ul>
                </div>
              </p>

              <hr class="short-separator-line"/>

              <h1 class="products-title">{t('products.system_example')}</h1>
              <p class="intro_p">{t('products.system3_description.p1')}</p>
              <p class="intro_p">{t('products.system3_description.p2')}</p>
              <p class="intro_p">{t('products.system3_description.p3')}</p>
              <p class="intro_p">{t('products.system3_description.p4')}</p>
              <p class="intro_p">{t('products.system3_description.p5')}</p>


              <div>  <ProductCardsSystem3Example /> </div>

              <hr class="short-separator-line"/>

              <h1 class="products-title">{t('products.required')}</h1>

              <div className="line-smooth-main">
                  <div className="line-smooth" onClick={() => setIsOpenSystem3Depth(!isOpenSystem3Depth)}>
                    <h2 class="line-smooth-title">{t('products.required_beacons')}</h2>
                    <span className={`arrow-line-smooth ${isOpenSystem3Depth ? 'rotate' : ''}`}>&#9660;</span>
                  </div>
                  <div
                    className="content-wrapper-smooth"
                    style={{
                      height: isOpenSystem3Depth ? `${contentHeightSystem3Depth}px` : '0',
                      overflow: 'hidden',
                      transition: 'height 0.3s ease-in-out'
                    }}
                  >
                  <div ref={contentRefSystem3Depth}>  <ProductCardsBeacons /> </div>
                </div>
              </div>

              <div className="line-smooth-main">
                  <div className="line-smooth" onClick={() => setIsOpenSystem3Mobile(!isOpenSystem3Mobile)}>
                    <h2 class="line-smooth-title">{t('products.required_beacons_mobile')}</h2>
                    <span className={`arrow-line-smooth ${isOpenSystem3Mobile ? 'rotate' : ''}`}>&#9660;</span>
                  </div>
                  <div
                    className="content-wrapper-smooth"
                    style={{
                      height: isOpenSystem3Mobile ? `${contentHeightSystem3Mobile}px` : '0',
                      overflow: 'hidden',
                      transition: 'height 0.3s ease-in-out'
                    }}
                  >
                  <div ref={contentRefSystem3Mobile}>  <ProductCardsBeaconsMobile /> </div>
                </div>
              </div>

              <div className="line-smooth-main">
                  <div className="line-smooth" onClick={() => setIsOpenSystem3Basic(!isOpenSystem3Basic)}>
                    <h2 class="line-smooth-title">{t('products.required_basic')}</h2>
                    <span className={`arrow-line-smooth ${isOpenSystem3Basic ? 'rotate' : ''}`}>&#9660;</span>
                  </div>
                  <div
                    className="content-wrapper-smooth"
                    style={{
                      height: isOpenSystem3Basic ? `${contentHeightSystem3Basic}px` : '0',
                      overflow: 'hidden',
                      transition: 'height 0.3s ease-in-out'
                    }}
                  >
                  <div ref={contentRefSystem3Basic}>  <ProductCardsModems /> </div>
                </div>
              </div>


              <div className="line-smooth-main">
                  <div className="line-smooth" onClick={() => setIsOpenSystem3Compact(!isOpenSystem3Compact)}>
                    <h2 class="line-smooth-title">{t('products.required_compact')}</h2>
                    <span className={`arrow-line-smooth ${isOpenSystem3Compact ? 'rotate' : ''}`}>&#9660;</span>
                  </div>
                  <div
                    className="content-wrapper-smooth"
                    style={{
                      height: isOpenSystem3Compact ? `${contentHeightSystem3Compact}px` : '0',
                      overflow: 'hidden',
                      transition: 'height 0.3s ease-in-out'
                    }}
                  >
                  <div ref={contentRefSystem3Compact}>  <ProductCardsCompacts /> </div>
                </div>
              </div>


              <div className="line-smooth-main">
                  <div className="line-smooth" onClick={() => setIsOpenSystem3OEM(!isOpenSystem3OEM)}>
                    <h2 class="line-smooth-title">{t('products.required_basic_oem')}</h2>
                    <span className={`arrow-line-smooth ${isOpenSystem3OEM ? 'rotate' : ''}`}>&#9660;</span>
                  </div>
                  <div
                    className="content-wrapper-smooth"
                    style={{
                      height: isOpenSystem3OEM ? `${contentHeightSystem3OEM}px` : '0',
                      overflow: 'hidden',
                      transition: 'height 0.3s ease-in-out'
                    }}
                  >
                  <div ref={contentRefSystem3OEM}>  <ProductCardsOEMModems /> </div>
                </div>
              </div>

              <div className="line-smooth-main">
                  <div className="line-smooth" onClick={() => setIsOpenSystem3Accessoires(!isOpenSystem3Accessoires)}>
                    <h2 class="line-smooth-title">{t('products.required_accessoires')}</h2>
                    <span className={`arrow-line-smooth ${isOpenSystem3Accessoires ? 'rotate' : ''}`}>&#9660;</span>
                  </div>
                  <div
                    className="content-wrapper-smooth"
                    style={{
                      height: isOpenSystem3Accessoires ? `${contentHeightSystem3Accessoires}px` : '0',
                      overflow: 'hidden',
                      transition: 'height 0.3s ease-in-out'
                    }}
                  >
                  <div ref={contentRefSystem3Accessoires}>  <ProductCardsAccessoires /> </div>
                </div>
              </div>

              </div>
              </div>
          </div>


          <div class="welcome-section">
            <div class="container-out">
            <div class="container">
              <h1 class="title" id="sensor-network-pos">{t('products.seller_cards.card_4.title')}</h1>
              <p class="intro">{t('products.seller_cards.card_4.intro')}</p>
              
              <p class="intro">
                <div class="top-seller-description">
                  <ul class="sensor-network-positioning-features">
                    <li><strong>{t('products.seller_cards.card_4.bullet_points.p1.feature')}</strong>{t('products.seller_cards.card_4.bullet_points.p1.description')}</li>
                    <li><strong>{t('products.seller_cards.card_4.bullet_points.p2.feature')}</strong>{t('products.seller_cards.card_4.bullet_points.p2.description')}</li>
                    <li><strong>{t('products.seller_cards.card_4.bullet_points.p3.feature')}</strong>{t('products.seller_cards.card_4.bullet_points.p3.description')}</li>
                    <li><strong>{t('products.seller_cards.card_4.bullet_points.p4.feature')}</strong>{t('products.seller_cards.card_4.bullet_points.p4.description')}</li>
                    <li><strong>{t('products.seller_cards.card_4.bullet_points.p5.feature')}</strong>{t('products.seller_cards.card_4.bullet_points.p5.description')}</li>
                    <li><strong>{t('products.seller_cards.card_4.bullet_points.p6.feature')}</strong>{t('products.seller_cards.card_4.bullet_points.p6.description')}</li>
                  </ul>
                </div>
              </p>

              <hr class="short-separator-line"/>

              <h1 class="products-title">{t('products.system_example')}</h1>
              <p class="intro_p">{t('products.system4_description.p1')}</p>
              <p class="intro_p">{t('products.system4_description.p2')}</p>
              <p class="intro_p">{t('products.system4_description.p3')}</p>
              <p class="intro_p">{t('products.system4_description.p4')}</p>

              <div>  <ProductCardsSystem4Example /> </div>

              <hr class="short-separator-line"/>

              <h1 class="products-title">{t('products.required')}</h1>

              <div className="line-smooth-main">
                  <div className="line-smooth" onClick={() => setIsOpenSystem4USBL(!isOpenSystem4USBL)}>
                    <h2 class="line-smooth-title">{t('products.required_positioning')}</h2>
                    <span className={`arrow-line-smooth ${isOpenSystem4USBL ? 'rotate' : ''}`}>&#9660;</span>
                  </div>
                  <div
                    className="content-wrapper-smooth"
                    style={{
                      height: isOpenSystem4USBL ? `${contentHeightSystem4USBL}px` : '0',
                      overflow: 'hidden',
                      transition: 'height 0.3s ease-in-out'
                    }}
                  >
                  <div ref={contentRefSystem4USBL}>  <ProductCardsUSBLModems /> </div>
                </div>
              </div>

              <div className="line-smooth-main">
                  <div className="line-smooth" onClick={() => setIsOpenSystem4Depth(!isOpenSystem4Depth)}>
                    <h2 class="line-smooth-title">{t('products.required_beacons')}</h2>
                    <span className={`arrow-line-smooth ${isOpenSystem4Depth ? 'rotate' : ''}`}>&#9660;</span>
                  </div>
                  <div
                    className="content-wrapper-smooth"
                    style={{
                      height: isOpenSystem4Depth ? `${contentHeightSystem4Depth}px` : '0',
                      overflow: 'hidden',
                      transition: 'height 0.3s ease-in-out'
                    }}
                  >
                  <div ref={contentRefSystem4Depth}>  <ProductCardsBeacons /> </div>
                </div>
              </div>

              <div className="line-smooth-main">
                  <div className="line-smooth" onClick={() => setIsOpenSystem4Mobile(!isOpenSystem4Mobile)}>
                    <h2 class="line-smooth-title">{t('products.required_beacons_mobile')}</h2>
                    <span className={`arrow-line-smooth ${isOpenSystem4Mobile ? 'rotate' : ''}`}>&#9660;</span>
                  </div>
                  <div
                    className="content-wrapper-smooth"
                    style={{
                      height: isOpenSystem4Mobile ? `${contentHeightSystem4Mobile}px` : '0',
                      overflow: 'hidden',
                      transition: 'height 0.3s ease-in-out'
                    }}
                  >
                  <div ref={contentRefSystem4Mobile}>  <ProductCardsBeaconsMobile /> </div>
                </div>
              </div>

              <div className="line-smooth-main">
                  <div className="line-smooth" onClick={() => setIsOpenSystem4Basic(!isOpenSystem4Basic)}>
                    <h2 class="line-smooth-title">{t('products.required_basic')}</h2>
                    <span className={`arrow-line-smooth ${isOpenSystem4Basic ? 'rotate' : ''}`}>&#9660;</span>
                  </div>
                  <div
                    className="content-wrapper-smooth"
                    style={{
                      height: isOpenSystem4Basic ? `${contentHeightSystem4Basic}px` : '0',
                      overflow: 'hidden',
                      transition: 'height 0.3s ease-in-out'
                    }}
                  >
                  <div ref={contentRefSystem4Basic}>  <ProductCardsModems /> </div>
                </div>
              </div>


              <div className="line-smooth-main">
                  <div className="line-smooth" onClick={() => setIsOpenSystem4Compact(!isOpenSystem4Compact)}>
                    <h2 class="line-smooth-title">{t('products.required_compact')}</h2>
                    <span className={`arrow-line-smooth ${isOpenSystem4Compact ? 'rotate' : ''}`}>&#9660;</span>
                  </div>
                  <div
                    className="content-wrapper-smooth"
                    style={{
                      height: isOpenSystem4Compact ? `${contentHeightSystem4Compact}px` : '0',
                      overflow: 'hidden',
                      transition: 'height 0.3s ease-in-out'
                    }}
                  >
                  <div ref={contentRefSystem4Compact}>  <ProductCardsCompacts /> </div>
                </div>
              </div>


              <div className="line-smooth-main">
                  <div className="line-smooth" onClick={() => setIsOpenSystem4OEM(!isOpenSystem4OEM)}>
                    <h2 class="line-smooth-title">{t('products.required_basic_oem')}</h2>
                    <span className={`arrow-line-smooth ${isOpenSystem4OEM ? 'rotate' : ''}`}>&#9660;</span>
                  </div>
                  <div
                    className="content-wrapper-smooth"
                    style={{
                      height: isOpenSystem4OEM ? `${contentHeightSystem4OEM}px` : '0',
                      overflow: 'hidden',
                      transition: 'height 0.3s ease-in-out'
                    }}
                  >
                  <div ref={contentRefSystem4OEM}>  <ProductCardsOEMModems /> </div>
                </div>
              </div>

              <div className="line-smooth-main">
                  <div className="line-smooth" onClick={() => setIsOpenSystem4Accessoires(!isOpenSystem4Accessoires)}>
                    <h2 class="line-smooth-title">{t('products.required_accessoires')}</h2>
                    <span className={`arrow-line-smooth ${isOpenSystem4Accessoires ? 'rotate' : ''}`}>&#9660;</span>
                  </div>
                  <div
                    className="content-wrapper-smooth"
                    style={{
                      height: isOpenSystem4Accessoires ? `${contentHeightSystem4Accessoires}px` : '0',
                      overflow: 'hidden',
                      transition: 'height 0.3s ease-in-out'
                    }}
                  >
                  <div ref={contentRefSystem4Accessoires}>  <ProductCardsAccessoires /> </div>
                </div>
              </div>

          </div>
          </div>
        </div>

        <div class="cta-section">
          <h2>{t('products.footer.title')}</h2>
          <p>{t('products.footer.intro')}</p>
          <button onClick={exploreMore}> {t('products.footer.button')} </button>
      </div>
      </div>
  </div>

  );
}
