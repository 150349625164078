import React from "react";
import { Link } from "react-router-dom"; 
import '../../i18n';
import { useTranslation } from 'react-i18next';

import ImageSpin57 from "../../assets/images/modems/processed/spin_57_usbl.png"

const Spin57_USBL = () => {
  const { t, i18n } = useTranslation();
  
  return (
    // Link to navigate and trigger the smooth scroll
    <Link to="/usbl-product#spin57_usbl_spec" className="card-link-wrapper">
      <div className="card">
        <img src={ImageSpin57} alt={t('products.types.communcation_57_USBL.title')} className="card-image" />
        <div className="card-content">
          <h3 className="card-title">{t('products.types.communcation_57_USBL.title')}</h3>
          <p className="card-description">
            <ul className="features-list">
              <li>{t('products.types.communcation_57_USBL.li_1')}</li>
              <li>{t('products.types.communcation_57_USBL.li_2')}</li>
              <li>{t('products.types.communcation_57_USBL.li_3')}</li>
              <li>{t('products.types.communcation_57_USBL.li_4')}</li>
            </ul>
          </p>
        </div>
      </div>
    </Link>
  );
};

export default Spin57_USBL;
