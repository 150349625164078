import React from 'react';
import './ModemDetails.css';
import '../../i18n';
import { useTranslation } from 'react-i18next';

import Flange from "../../assets/images/flanec.png"

const Flange_SPEC = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="spec-container">
      <div className="spec-header-image">
        <img src={Flange} className="spec-header-image-img" />
      </div>

      <div className="spec-item full-widtli>h">
        <h2 className="spec-title">{t('accessoires.flange.details.title')}</h2>
        <ul className="spec-details">
          <li>{t('accessoires.flange.details.p1')}</li>
          <li>{t('accessoires.flange.details.p2')}</li>
          <li>{t('accessoires.flange.details.p3')}</li>
          <li>{t('accessoires.flange.details.p4')}</li>
          <li>{t('accessoires.flange.details.p5')}</li>
          <li>{t('accessoires.flange.details.p6')}</li>
        </ul>
      </div>

    </div>
  );
};

export default Flange_SPEC;
