import React from 'react';
import './ModemDetails.css';
import '../../i18n';
import { useTranslation } from 'react-i18next';

import ImageSpin26H from "../../assets/images/modems/processed/spin_26H_oem.png"

const Spin26H_OEM_SPEC = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="spec-container">
      <div className="spec-header-image">
        <img src={ImageSpin26H} className="spec-header-image-img" />
      </div>

      <div className="spec-item full-width">
        <h2 className="spec-title">{t('spin26H_oem_spec.details.title')}</h2>
        <ul className="spec-details">
          <li>{t('spin26H_oem_spec.details.p1')}</li>
          <li>{t('spin26H_oem_spec.details.p2')}</li>
          <li>{t('spin26H_oem_spec.details.p3')}</li>
          <li>{t('spin26H_oem_spec.details.p4')}</li>
          <li>{t('spin26H_oem_spec.details.p5')}</li>
          <li>{t('spin26H_oem_spec.details.p6')}</li>
          <li>{t('spin26H_oem_spec.details.p7')}</li>
        </ul>
      </div>

      <div className="spec-items-row">
        <div className="spec-item full-width">
          <h2 className="spec-title">{t('spin26H_oem_spec.interface.title')}</h2>
          <ul className="spec-details">
            <li>{t('spin26H_oem_spec.interface.p1')}</li>
            <li>{t('spin26H_oem_spec.interface.p2')}</li>
            <li>{t('spin26H_oem_spec.interface.p3')}</li>
            <li>{t('spin26H_oem_spec.interface.p4')}</li>
          </ul>
        </div>
      </div>

    </div>
  );
};

export default Spin26H_OEM_SPEC;
