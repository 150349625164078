import React from "react";
import "./ProductCardsModems.css";
import { Link } from "react-router-dom"; 
import '../i18n';
import { useTranslation } from 'react-i18next';

import Spin12_COMPACT from './product_cards/Spin12_COMPACT'
import Spin21_COMPACT from './product_cards/Spin21_COMPACT'
import Spin26_COMPACT from './product_cards/Spin26_COMPACT'
import Spin26H_COMPACT from './product_cards/Spin26_COMPACT'
import Spin57_COMPACT from './product_cards/Spin57_COMPACT'

const ProductCardsCompacts = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <div className="Products">
      <div class="card-list-modems">
       
        <Spin12_COMPACT />
        <Spin21_COMPACT />
        <Spin26_COMPACT />
        <Spin26H_COMPACT />
        <Spin57_COMPACT />

      </div>
    </div>
  );
};
export default ProductCardsCompacts;