import React, { useState, useEffect } from 'react';
import './ImageSlider.css';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

// import Img1_RU from "../assets/images/slider/ready_solutions_small_wtext.jpg"
// import Img2_RU from "../assets/images/slider/positioning_small_wtext.jpg"
// import Img3_RU from "../assets/images/slider/comm_small_wtext.jpg"
// import Img4_RU from "../assets/images/slider/lanas_small_wtext.jpg"
// import Img5_RU from "../assets/images/slider/modem_quality_small_wtext.jpg"

import Img1_EN from "../assets/images/slider/ready_solutions_small_wtext.jpg"
import Img2_EN from "../assets/images/slider/positioning_small_wtext.jpg"
import Img3_EN from "../assets/images/slider/comm_small_wtext.jpg"
import Img4_EN from "../assets/images/slider/lanas_small_wtext.jpg"
import Img5_EN from "../assets/images/slider/modem_quality_small_wtext.jpg"

const images = [
    Img1_EN,
    Img2_EN,
    Img3_EN,
    Img4_EN,
    Img5_EN
  ];

const ImageSlider = ({ interval = 3000 }) => {
  const { t, i18n } = useTranslation();

  const textContent = [
    t('imageSlider.i1'),
    t('imageSlider.i2'),
    t('imageSlider.i3'),
    t('imageSlider.i4'),
    t('imageSlider.i5')
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [sliderImages, setSliderImages] = useState(images);

  // Change images
  useEffect(() => {
    setSliderImages(images);
    setCurrentIndex(0);
  }, [images]);

  // // Subscribe language change event
  // useEffect(() => {
  //   const handleLanguageChange = (lng) => {
  //     switch(lng) {
  //       case 'ru' :
  //           setSliderImages([
  //             Img1_RU,
  //             Img2_RU,
  //             Img3_RU,
  //             Img4_RU,
  //             Img5_RU
  //           ]);
  //           break;
  //       default :
  //           setSliderImages([
  //             Img1_EN,
  //             Img2_EN,
  //             Img3_EN,
  //             Img4_EN,
  //             Img5_EN
  //           ]);
  //           break;
  //     }
  //   };

  //   i18n.on('languageChanged', handleLanguageChange);

  //   return () => {
  //     i18n.off('languageChanged', handleLanguageChange);
  //   };
  // }, []);

  // Automatic image slider 
  useEffect(() => {
    const timer = setTimeout(() => {
      handleNext();
    }, interval);

    return () => clearTimeout(timer);

  }, [currentIndex, interval]);

  // Go to the next slide
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === sliderImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Go to the previous slide
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? sliderImages.length - 1 : prevIndex - 1
    );
  };

  // Jump to a specific slide
  const goToSlide = (index) => {
    setCurrentIndex(index);
  };


  return (
    <div className="slider">
      {/* Slides */}
      {sliderImages.map((image, index) => (
        <div
          key={index}
          className={`slide ${index === currentIndex ? 'active' : ''}`}
          style={{
            backgroundImage: `url(${image})`,
          }}
        >
        {/* Text overlay */}
        {index === currentIndex && (
          <div className={`slide-text-${index}`} >
            <h2>{textContent[index]}</h2>
          </div>
        )}

        </div>
      ))}

      {/* Left and Right Navigation Buttons */}
      <button className="nav-button left" onClick={handlePrev}>
        &#10094;
      </button>
      <button className="nav-button right" onClick={handleNext}>
        &#10095;
      </button>

      {/* Dots for navigation */}
      <div className="dots">
        {sliderImages.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;