import React from "react";
import "../ProductCardsModems.css";
import { Link } from "react-router-dom"; 
import '../../i18n';
import { useTranslation } from 'react-i18next';

import ImageSpin26Mobile from "../../assets/images/modems/processed/transponder_mobile_spin26.png"

const Spin26_MOBILE = () => {
  const { t, i18n } = useTranslation();
  
  return (
     <Link to="/acoustic_modems#spin26_mobile_spec" className="card-link-wrapper">
          <div class="card-beacon">
            <img src={ImageSpin26Mobile} alt={t('products.types.communcation_26_BEACON_MOBILE.title')} class="card-image" />
            <div class="card-content">
              <h3 class="card-title">{t('products.types.communcation_26_BEACON_MOBILE.title')}</h3>
              <p className="card-description">
                <ul class="features-list">
                  <li>{t('products.types.communcation_26_BEACON_MOBILE.li_1')}</li>
                  <li>{t('products.types.communcation_26_BEACON_MOBILE.li_2')}</li>
                  <li>{t('products.types.communcation_26_BEACON_MOBILE.li_3')}</li>
                  <li>{t('products.types.communcation_26_BEACON_MOBILE.li_4')}</li>
                </ul>
              </p>
            </div>
          </div>
        </Link>
  );
};
export default Spin26_MOBILE;