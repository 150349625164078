import React from "react";
import "../ProductCardsModems.css";
import { Link } from "react-router-dom"; 
import '../../i18n';
import { useTranslation } from 'react-i18next';

import ImageSpin26H from "../../assets/images/modems/processed/spin_26H_usbl.png"

const Spin26H_USBL = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <Link to="/usbl-product#spin26H_usbl_spec" className="card-link-wrapper">
      <div class="card">
        <img src={ImageSpin26H} alt={t('products.types.communcation_26H_USBL.title')} class="card-image" />
        <div class="card-content">
          <h3 class="card-title">{t('products.types.communcation_26H_USBL.title')}</h3>
          <p className="card-description">
            <ul class="features-list">
              <li>{t('products.types.communcation_26H_USBL.li_1')}</li>
              <li>{t('products.types.communcation_26H_USBL.li_2')}</li>
              <li>{t('products.types.communcation_26H_USBL.li_3')}</li>
              <li>{t('products.types.communcation_26H_USBL.li_4')}</li>
            </ul>
          </p>
        </div>
      </div>
    </Link>
  );
};
export default Spin26H_USBL;