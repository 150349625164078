import React from 'react';
import './ModemDetails.css';
import '../../i18n';
import { useTranslation } from 'react-i18next';

import Float from "../../assets/images/modems/processed/float.png"

const Float_SPEC = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="spec-container">
      <div className="spec-header-image">
        <img src={Float} className="spec-header-image-img" />
      </div>

      <div className="spec-item full-width">
        <h2 className="spec-title">{t('accessoires.float.details.title')}</h2>
        <p>{t('accessoires.float.details.p1')}</p>
      </div>

      <div className="spec-items-row">
        <div className="spec-item half-width-50">
          <ul className="spec-details">
            <h2 className="spec-title">{t('accessoires.float.details.f1.title')}</h2>
            <li>{t('accessoires.float.details.f1.p1')}</li>
            <li>{t('accessoires.float.details.f1.p2')}</li>
            <li>{t('accessoires.float.details.f1.p3')}</li>
          </ul>
        </div>
        <div className="spec-item half-width-50">
          <ul className="spec-details">
            <h2 className="spec-title">{t('accessoires.float.details.f2.title')}</h2>
            <li>{t('accessoires.float.details.f2.p1')}</li>
            <li>{t('accessoires.float.details.f2.p2')}</li>
            <li>{t('accessoires.float.details.f2.p3')}</li>
          </ul>
        </div>
      </div>

    </div>
  );
};

export default Float_SPEC;
