import React from "react";
import "./ProductCardsModems.css";
import { Link } from "react-router-dom"; 
import '../i18n';
import { useTranslation } from 'react-i18next';

import Float from "../assets/images/modems/processed/float.png"
import Releaser from "../assets/images/modems/processed/releaser_1.png"
import XSENS from "../assets/images/xsens.png"
import PressureSensor from "../assets/images/pressure_sensor.png"
import PowerOn from "../assets/images/power-on.png"
import Flanec from "../assets/images/flanec.png"
import Wakeup from "../assets/images/wakeup.png"
import Ramka from "../assets/images/ramka.png"
import Battery from "../assets/images/battery.png"

const ProductCardsAccessoires = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <div className="Products">
      <div class="card-list-modems">
        <Link to="/accessoires#float" className="card-link-wrapper"> {/* Wrap card with Link */}
          <div className="card">
            <img src={Float} alt={t('products.types.accessoir_float.title')} className="card-image" />
            <div className="card-content">
              <h3 className="card-title">{t('products.types.accessoir_float.title')}</h3>
              <p className="card-description">
                <ul class="features-list">
                  <li>{t('products.types.accessoir_float.li_1')}</li>
                  <li>{t('products.types.accessoir_float.li_2')}</li>
                  <li>{t('products.types.accessoir_float.li_3')}</li>
                </ul>
              </p>
            </div>
          </div>
        </Link>

        <Link to="/accessoires#releaser" className="card-link-wrapper">
        <div className="card">
            <img src={Releaser} alt={t('products.types.accessoir_releaser.title')} className="card-image" />
            <div className="card-content">
              <h3 className="card-title">{t('products.types.accessoir_releaser.title')}</h3>
              <p className="card-description">
                <ul class="features-list">
                  <li>{t('products.types.accessoir_releaser.li_1')}</li>
                  <li>{t('products.types.accessoir_releaser.li_2')}</li>
                  <li>{t('products.types.accessoir_releaser.li_3')}</li>
                  <li>{t('products.types.accessoir_releaser.li_4')}</li>
                </ul>
              </p>
            </div>
          </div>
        </Link>

        <Link to="/accessoires#xsens" className="card-link-wrapper">
        <div className="card">
            <img src={XSENS} alt={t('products.types.accessoir_xsens.title')} className="card-image" />
            <div className="card-content">
              <h3 className="card-title">{t('products.types.accessoir_xsens.title')}</h3>
              <p className="card-description">
                <ul class="features-list">
                  <li>{t('products.types.accessoir_xsens.li_1')}</li>
                  <li>{t('products.types.accessoir_xsens.li_2')}</li>
                </ul>
              </p>
            </div>
          </div>
        </Link>

        <Link to="/accessoires#pressure" className="card-link-wrapper">
        <div className="card">
            <img src={PressureSensor} alt={t('products.types.accessoir_pressure.title')} className="card-image" />
            <div className="card-content">
              <h3 className="card-title">{t('products.types.accessoir_pressure.title')}</h3>
              <p className="card-description">
                <ul class="features-list">
                  <li>{t('products.types.accessoir_pressure.li_1')}</li>
                  <li>{t('products.types.accessoir_pressure.li_2')}</li>
                  <li>{t('products.types.accessoir_pressure.li_3')}</li>
                </ul>
              </p>
            </div>
          </div>
        </Link>

        <Link to="/accessoires#poweron" className="card-link-wrapper">
        <div className="card">
            <img src={PowerOn} alt={t('products.types.accessoir_poweron.title')} className="card-image" />
            <div className="card-content">
              <h3 className="card-title">{t('products.types.accessoir_poweron.title')}</h3>
              <p className="card-description">
                <ul class="features-list">
                  <li>{t('products.types.accessoir_poweron.li_1')}</li>
                  <li>{t('products.types.accessoir_poweron.li_2')}</li>
                </ul>
              </p>
            </div>
          </div>
        </Link>

        <Link to="/accessoires#flange" className="card-link-wrapper">
        <div className="card">
            <img src={Flanec} alt={t('products.types.accessoir_flanec.title')} className="card-image" />
            <div className="card-content">
              <h3 className="card-title">{t('products.types.accessoir_flanec.title')}</h3>
              <p className="card-description">
                <ul class="features-list">
                  <li>{t('products.types.accessoir_flanec.li_1')}</li>
                  <li>{t('products.types.accessoir_flanec.li_2')}</li>
                </ul>
              </p>
            </div>
          </div>
        </Link>

        <Link to="/accessoires#wakeup" className="card-link-wrapper">
        <div className="card">
            <img src={Wakeup} alt={t('products.types.accessoir_wakeup.title')} className="card-image" />
            <div className="card-content">
              <h3 className="card-title">{t('products.types.accessoir_wakeup.title')}</h3>
              <p className="card-description">
                <ul class="features-list">
                  <li>{t('products.types.accessoir_wakeup.li_1')}</li>
                </ul>
              </p>
            </div>
          </div>
        </Link>

        <Link to="/accessoires#frame" className="card-link-wrapper">
        <div className="card">
            <img src={Ramka} alt={t('products.types.accessoir_ramka.title')} className="card-image" />
            <div className="card-content">
              <h3 className="card-title">{t('products.types.accessoir_ramka.title')}</h3>
              <p className="card-description">
                <ul class="features-list">
                  <li>{t('products.types.accessoir_ramka.li_1')}</li>
                </ul>
              </p>
            </div>
          </div>
        </Link>

        <Link to="/accessoires#battery" className="card-link-wrapper">
        <div className="card">
            <img src={Battery} alt={t('products.types.accessoir_battery.title')} className="card-image" />
            <div className="card-content">
              <h3 className="card-title">{t('products.types.accessoir_battery.title')}</h3>
              <p className="card-description">
                <ul class="features-list">
                  <li>{t('products.types.accessoir_battery.li_1')}</li>
                </ul>
              </p>
            </div>
          </div>
        </Link>

      </div>
    </div>
  );
};
export default ProductCardsAccessoires;