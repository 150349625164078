import React from "react";
import "./ProductCardsModems.css";
import { Link } from "react-router-dom"; 
import '../i18n';
import { useTranslation } from 'react-i18next';

import Spin12_OEM from './product_cards/Spin12_OEM'
import Spin21_OEM from './product_cards/Spin21_OEM'
import Spin26H_OEM from './product_cards/Spin26H_OEM'
import Spin26_OEM from './product_cards/Spin26_OEM'
import Spin57_OEM from './product_cards/Spin57_OEM'

const ProductCardsOEMModems = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <div className="Products">
      <div class="card-list-modems">
        
        <Spin12_OEM />
        <Spin21_OEM />
        <Spin26_OEM />
        <Spin26H_OEM />
        <Spin57_OEM />

      </div>
    </div>
  );
};
export default ProductCardsOEMModems;