import React from "react";
import "./ProductCardsModems.css";
import { Link } from "react-router-dom"; 
import '../i18n';
import { useTranslation } from 'react-i18next';

import Spin12 from './product_cards/Spin12'
import Spin21 from './product_cards/Spin21'
import Spin26H from './product_cards/Spin26H'
import Spin26 from './product_cards/Spin26'
import Spin57 from './product_cards/Spin57'

const ProductCardsModems = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <div className="Products">
      <div class="card-list-modems">
        
        <Spin12 />
        <Spin21 />
        <Spin26 />
        <Spin26H />
        <Spin57 />

      </div>
    </div>
  );
};
export default ProductCardsModems;