import React from "react";
import "./ProductCardsModems.css";
import { Link } from "react-router-dom"; 
import '../i18n';
import { useTranslation } from 'react-i18next';

import Spin26H from './product_cards/Spin26H'
import Spin26H_OEM from './product_cards/Spin26H_OEM'
import Spin26H_MOBILE from './product_cards/Spin26H_MOBILE'
import Spin26H_BEACON from './product_cards/Spin26H_BEACON'


const ProductCardsSystem3Example = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <div className="Products">
      <div class="card-list-modems">
        
        <Spin26H />
        <Spin26H_OEM />
        <Spin26H_MOBILE />
        <Spin26H_BEACON />
        <Spin26H_BEACON />
        
      </div>
    </div>
  );
};
export default ProductCardsSystem3Example;