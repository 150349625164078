export const MenuItemsProducts = [
	{
		name : 'systems',
		path : '/products',
		cName : 'dropdown-link'
	},
	{
		name : 'acoustic_modems',
		path : '/acoustic_modems',
		cName : 'dropdown-link'
	},
	{
		name : 'positioning',
		path : '/usbl-product',
		cName : 'dropdown-link'
	},
	{
		name : 'software',
		path : '/software',
		cName : 'dropdown-link'
	},
	{
		name : 'accessoires',
		path : '/accessoires',
		cName : 'dropdown-link'
	}
];

export const MenuItemsLanguages = [
	{
		title : 'English',
		value : 'en',
		cName : 'dropdown-link'
	},
	{
		title : 'Русский',
		value : 'ru',
		cName : 'dropdown-link'
	}
];