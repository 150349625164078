import React from "react";
import "./ProductCardsModems.css";
import { Link } from "react-router-dom"; 
import '../i18n';
import { useTranslation } from 'react-i18next';

import Spin26 from './product_cards/Spin26'
import Spin26_OEM from './product_cards/Spin26_OEM'

const ProductCardsSystem1Example = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <div className="Products">
      <div class="card-list-modems">
       <Spin26 />
       <Spin26_OEM />
      </div>
    </div>
  );
};
export default ProductCardsSystem1Example;