import React from 'react';
import './ModemDetails.css';
import '../../i18n';
import { useTranslation } from 'react-i18next';

import ImageSpin21 from "../../assets/images/modems/processed/compact_spin21.png"

const Spin21_COMPACT_SPEC = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="spec-container">
      <div className="spec-header-image">
        <img src={ImageSpin21} className="spec-header-image-img" />
      </div>

      <div className="spec-item full-width">
        <h2 className="spec-title">{t('spin21_compact_spec.details.title')}</h2>
        <ul className="spec-details">
          <li>{t('spin21_compact_spec.details.p1')}</li>
          <li>{t('spin21_compact_spec.details.p2')}</li>
          <li>{t('spin21_compact_spec.details.p3')}</li>
          <li>{t('spin21_compact_spec.details.p4')}</li>
          <li>{t('spin21_compact_spec.details.p5')}</li>
          <li>{t('spin21_compact_spec.details.p6')}</li>
          <li>{t('spin21_compact_spec.details.p7')}</li>
        </ul>
      </div>

      <div className="spec-items-row">
        <div className="spec-item half-width">
          <h2 className="spec-title">{t('spin21_compact_spec.housing.title')}</h2>
          <ul className="spec-details">
            <li>{t('spin21_compact_spec.housing.p1')}</li>
            <li>{t('spin21_compact_spec.housing.p2')}</li>
            <li>{t('spin21_compact_spec.housing.p3')}</li>
          </ul>
        </div>

        <div className="spec-item half-width">
          <h2 className="spec-title">{t('spin21_compact_spec.interface.title')}</h2>
          <ul className="spec-details">
            <li>{t('spin21_compact_spec.interface.p1')}</li>
            <li>{t('spin21_compact_spec.interface.p2')}</li>
            <li>{t('spin21_compact_spec.interface.p3')}</li>
            <li>{t('spin21_compact_spec.interface.p4')}</li>
          </ul>
        </div>

        <div className="spec-item half-width">
          <h2 className="spec-title">{t('spin21_compact_spec.options.title')}</h2>
          <ul className="spec-details">
            <li>{t('spin21_compact_spec.options.p1')}</li>
            <li>{t('spin21_compact_spec.options.p2')}</li>
          </ul>
          <div className="learn-more-div">
            <a href="/accessoires" class="learn-more-button">{t('products.seller_cards.button')}</a>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Spin21_COMPACT_SPEC;
