import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import './Products.css';
import './USBLProduct.css';
import '../ProductCards.css';
import { useTranslation } from 'react-i18next';

import ImageCommunication from "../../assets/images/communication.png"
import ProductCardsModems from "../ProductCardsModems.js"
import ProductCardsOEMModems from "../ProductCardsOEMModems.js"
import ProductCardsCompacts from "../ProductCardsCompacts.js"
import ProductCardsBeacons from "../ProductCardsBeacons.js"
import ProductCardsBeaconsMobile from "../ProductCardsBeaconsMobile.js"

import Spin57_SPEC from '../product_spec/Spin57_SPEC'
import Spin26H_SPEC from '../product_spec/Spin26H_SPEC'
import Spin26_SPEC from '../product_spec/Spin26_SPEC'
import Spin12_SPEC from '../product_spec/Spin12_SPEC'
import Spin21_SPEC from '../product_spec/Spin21_SPEC'

import Spin12_OEM_SPEC from '../product_spec/Spin12_OEM_SPEC'
import Spin57_OEM_SPEC from '../product_spec/Spin57_OEM_SPEC'
import Spin26H_OEM_SPEC from '../product_spec/Spin26H_OEM_SPEC'
import Spin26_OEM_SPEC from '../product_spec/Spin26_OEM_SPEC'
import Spin21_OEM_SPEC from '../product_spec/Spin21_OEM_SPEC'

import Spin12_COMPACT_SPEC from '../product_spec/Spin12_COMPACT_SPEC'
import Spin57_COMPACT_SPEC from '../product_spec/Spin57_COMPACT_SPEC'
import Spin26H_COMPACT_SPEC from '../product_spec/Spin26H_COMPACT_SPEC'
import Spin26_COMPACT_SPEC from '../product_spec/Spin26_COMPACT_SPEC'
import Spin21_COMPACT_SPEC from '../product_spec/Spin21_COMPACT_SPEC'

import Spin12_BEACON_SPEC from '../product_spec/Spin12_BEACON_SPEC'
import Spin21_BEACON_SPEC from '../product_spec/Spin21_BEACON_SPEC'
import Spin26_BEACON_SPEC from '../product_spec/Spin26_BEACON_SPEC'
import Spin26H_BEACON_SPEC from '../product_spec/Spin26H_BEACON_SPEC'
import Spin57_BEACON_SPEC from '../product_spec/Spin57_BEACON_SPEC'

import Spin12_MOBILE_SPEC from '../product_spec/Spin12_MOBILE_SPEC'
import Spin21_MOBILE_SPEC from '../product_spec/Spin21_MOBILE_SPEC'
import Spin26_MOBILE_SPEC from '../product_spec/Spin26_MOBILE_SPEC'
import Spin26H_MOBILE_SPEC from '../product_spec/Spin26H_MOBILE_SPEC'
import Spin57_MOBILE_SPEC from '../product_spec/Spin57_MOBILE_SPEC'

export default function AcousticModems() {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const navigate = useNavigate();

  const exploreMore = () => {
    navigate('/contact');
  };
  

  // Scroll to the section when the page is loaded or route changes
  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: "auto" });
      }
    }
  }, [location]);

  return (
    <div className="product-page" id="main">
      <div className="product-transparent-box">
        <div className="hero-section">
          <h1>{t('acoustic_modems.welcome')}</h1>
          <p>{t('acoustic_modems.discover')}</p>
        </div>

        <div className="usbl-info-container">
          <section className="usbl-section">
            <h2>{t('acoustic_modems.principle.title')}</h2>
            <p>{t('acoustic_modems.principle.text_p1')}</p>
            <p>{t('acoustic_modems.principle.text_p2')}</p>
          </section>

          <section className="usbl-section">
            <div className="usbl-content">
              <div className="usbl-text">
                <h2>{t('acoustic_modems.components.title')}</h2>
                <p>{t('acoustic_modems.components.description_1')}</p>
                <p>{t('acoustic_modems.components.description_2')}</p>

                <p><strong>{t('acoustic_modems.components.c1.name')}</strong> {t('acoustic_modems.components.c1.description')}</p>
                <p><strong>{t('acoustic_modems.components.c2.name')}</strong> {t('acoustic_modems.components.c2.description')}</p>
                <p><strong>{t('acoustic_modems.components.c3.name')}</strong> {t('acoustic_modems.components.c3.description')}</p>
                <p><strong>{t('acoustic_modems.components.c4.name')}</strong> {t('acoustic_modems.components.c4.description')}</p>
                <p><strong>{t('acoustic_modems.components.c5.name')}</strong> {t('acoustic_modems.components.c5.description')}</p>
                <p><strong>{t('acoustic_modems.components.c6.name')}</strong> {t('acoustic_modems.components.c6.description')}</p>
                <p><strong>{t('acoustic_modems.components.c7.name')}</strong> {t('acoustic_modems.components.c7.description')}</p>
                <p><strong>{t('acoustic_modems.components.c8.name')}</strong> {t('acoustic_modems.components.c8.description')}</p>
                <p><strong>{t('acoustic_modems.components.c9.name')}</strong> {t('acoustic_modems.components.c9.description')}</p>
                <p><strong>{t('acoustic_modems.components.c10.name')}</strong> {t('acoustic_modems.components.c10.description')}</p>
                <p><strong>{t('acoustic_modems.components.c11.name')}</strong> {t('acoustic_modems.components.c11.description')}</p>
                <p><strong>{t('acoustic_modems.components.c12.name')}</strong> {t('acoustic_modems.components.c12.description')}</p>
                <p><strong>{t('acoustic_modems.components.c13.name')}</strong> {t('acoustic_modems.components.c13.description')}</p>
                
              </div>
              <div className="usbl-image">
                <img src={ImageCommunication} className="pos-image" alt="Communication" />
              </div>
            </div>
          </section>


          <section className="usbl-section">
            <h2>{t('acoustic_modems.application.title')}</h2>
            <p><strong>{t('acoustic_modems.application.c1.name')}</strong> {t('acoustic_modems.application.c1.description')}</p>
            <p><strong>{t('acoustic_modems.application.c2.name')}</strong> {t('acoustic_modems.application.c2.description')}</p>
            <p><strong>{t('acoustic_modems.application.c3.name')}</strong> {t('acoustic_modems.application.c3.description')}</p>
            <p><strong>{t('acoustic_modems.application.c4.name')}</strong> {t('acoustic_modems.application.c4.description')}</p>
          </section>

          <section className="usbl-section">
            <h2>{t('acoustic_modems.types.modems.title')}</h2>
            <p>{t('acoustic_modems.types.modems.description')}</p>
          </section>

          <div>  <ProductCardsModems /> </div>

          <section className="usbl-section">
            <h2>{t('acoustic_modems.types.oem.title')}</h2>
            <p>{t('acoustic_modems.types.oem.description')}</p>
          </section>

          <div>  <ProductCardsOEMModems /> </div>

          <section className="usbl-section">
            <h2>{t('acoustic_modems.types.mini.title')}</h2>
            <p>{t('acoustic_modems.types.mini.description')}</p>
          </section>

          <div>  <ProductCardsCompacts /> </div>

          <section className="usbl-section">
            <h2>{t('acoustic_modems.types.beacon.title')}</h2>
            <p>{t('acoustic_modems.types.beacon.description')}</p>
          </section>

          <div>  <ProductCardsBeacons /> </div>

          <section className="usbl-section">
            <h2>{t('acoustic_modems.types.beacon_mobile.title')}</h2>
            <p>{t('acoustic_modems.types.beacon_mobile.description')}</p>
          </section>

          <div>  <ProductCardsBeaconsMobile /> </div>

          
          <section className="usbl-section">
            <h2 id="spin12_spec">{t('spin12_spec.title')}</h2>
            <div>  <Spin12_SPEC /> </div>
          </section>

          <section className="usbl-section">
            <h2 id="spin21_spec">{t('spin21_spec.title')}</h2>
            <div>  <Spin21_SPEC /> </div>
          </section>


          <section className="usbl-section">
            <h2 id="spin26_spec">{t('spin26_spec.title')}</h2>
            <div>  <Spin26_SPEC /> </div>
          </section>

          <section className="usbl-section">
            <h2 id="spin26H_spec">{t('spin26H_spec.title')}</h2>
            <div>  <Spin26H_SPEC /> </div>
          </section>

          <section className="usbl-section">
            <h2 id="spin57_spec">{t('spin57_spec.title')}</h2>
            <div>  <Spin57_SPEC /> </div>
          </section>


          <section className="usbl-section">
            <h2 id="spin12_oem_spec">{t('spin12_oem_spec.title')}</h2>
            <div>  <Spin12_OEM_SPEC /> </div>
          </section>

          <section className="usbl-section">
            <h2 id="spin21_oem_spec">{t('spin21_oem_spec.title')}</h2>
            <div>  <Spin21_OEM_SPEC /> </div>
          </section>


          <section className="usbl-section">
            <h2 id="spin26_oem_spec">{t('spin26_oem_spec.title')}</h2>
            <div>  <Spin26_OEM_SPEC /> </div>
          </section>

          <section className="usbl-section">
            <h2 id="spin26H_oem_spec">{t('spin26H_oem_spec.title')}</h2>
            <div>  <Spin26H_OEM_SPEC /> </div>
          </section>

          <section className="usbl-section">
            <h2 id="spin57_oem_spec">{t('spin57_oem_spec.title')}</h2>
            <div>  <Spin57_OEM_SPEC /> </div>
          </section>



          <section className="usbl-section">
            <h2 id="spin12_compact_spec">{t('spin12_compact_spec.title')}</h2>
            <div>  <Spin12_COMPACT_SPEC /> </div>
          </section>

          <section className="usbl-section">
            <h2 id="spin21_compact_spec">{t('spin21_compact_spec.title')}</h2>
            <div>  <Spin21_COMPACT_SPEC /> </div>
          </section>


          <section className="usbl-section">
            <h2 id="spin26_compact_spec">{t('spin26_compact_spec.title')}</h2>
            <div>  <Spin26_COMPACT_SPEC /> </div>
          </section>

          <section className="usbl-section">
            <h2 id="spin26H_compact_spec">{t('spin26H_compact_spec.title')}</h2>
            <div>  <Spin26H_COMPACT_SPEC /> </div>
          </section>

          <section className="usbl-section">
            <h2 id="spin57_compact_spec">{t('spin57_compact_spec.title')}</h2>
            <div>  <Spin57_COMPACT_SPEC /> </div>
          </section>

          <section className="usbl-section">
            <h2 id="spin12_beacon_spec">{t('spin12_beacon_spec.title')}</h2>
            <div>  <Spin12_BEACON_SPEC /> </div>
          </section>

           <section className="usbl-section">
            <h2 id="spin21_beacon_spec">{t('spin21_beacon_spec.title')}</h2>
            <div>  <Spin21_BEACON_SPEC /> </div>
          </section>

          <section className="usbl-section">
            <h2 id="spin26_beacon_spec">{t('spin26_beacon_spec.title')}</h2>
            <div>  <Spin26_BEACON_SPEC /> </div>
          </section>

          <section className="usbl-section">
            <h2 id="spin26H_beacon_spec">{t('spin26H_beacon_spec.title')}</h2>
            <div>  <Spin26H_BEACON_SPEC /> </div>
          </section>

          <section className="usbl-section">
            <h2 id="spin57_beacon_spec">{t('spin57_beacon_spec.title')}</h2>
            <div>  <Spin57_BEACON_SPEC /> </div>
          </section>

          <section className="usbl-section">
            <h2 id="spin12_mobile_spec">{t('spin12_mobile_spec.title')}</h2>
            <div>  <Spin12_MOBILE_SPEC /> </div>
          </section>

          <section className="usbl-section">
            <h2 id="spin21_mobile_spec">{t('spin21_mobile_spec.title')}</h2>
            <div>  <Spin21_MOBILE_SPEC /> </div>
          </section>

          <section className="usbl-section">
            <h2 id="spin26_mobile_spec">{t('spin26_mobile_spec.title')}</h2>
            <div>  <Spin26_MOBILE_SPEC /> </div>
          </section>

          <section className="usbl-section">
            <h2 id="spin26H_mobile_spec">{t('spin26H_mobile_spec.title')}</h2>
            <div>  <Spin26H_MOBILE_SPEC /> </div>
          </section>

          <section className="usbl-section">
            <h2 id="spin57_mobile_spec">{t('spin57_mobile_spec.title')}</h2>
            <div>  <Spin57_MOBILE_SPEC /> </div>
          </section>

        </div>

        <div className="cta-section">
          <h2>{t('acoustic_modems.footer.title')}</h2>
          <p>{t('acoustic_modems.footer.intro')}</p>
          <button onClick={exploreMore}> {t('products.footer.button')} </button>
        </div>
      </div>
    </div>
  );
}
